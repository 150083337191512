import { useEffect, useState } from 'react'
import highWallDataProvider from 'src/dataProvider/highWallDataProvider'

function PresignedImage({
  filename,
  alt = '',
  ...props
}: {
  filename: string
  alt?: string
} & React.ImgHTMLAttributes<HTMLImageElement>) {
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null)

  useEffect(() => {
    const fetchPresignedUrl = async () => {
      try {
        const result = await highWallDataProvider.fetch(
          `/storage/files?filename=${filename}`
        )
        if (result?.json?.url) {
          setPresignedUrl(result.json.url)
        } else {
          alert('沒有照片')
        }
      } catch (error) {
        console.error(error)
        alert('發生錯誤')
      }
    }

    fetchPresignedUrl()
  }, [filename])

  return presignedUrl ? <img src={presignedUrl} alt={alt} {...props} /> : null
}

export default PresignedImage
