import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import makeStyles from '@mui/styles/makeStyles'
import { ResponsiveLine, Serie } from '@nivo/line'
import Loading from 'src/Components/Loading/Loading'

const useStyles = makeStyles(() => ({
  chart: {
    height: 290,
  },
}))

interface RentRecordChartProps {
  data: Serie[]
  total?: number
  error?: unknown
  isLoading: boolean
}
const RentRecordChart = ({
  data,
  total,
  error,
  isLoading,
}: RentRecordChartProps) => {
  const classes = useStyles()

  if (error) {
    return <h3>error</h3>
  }
  if (isLoading) {
    return (
      <Card>
        <CardHeader title="使用量" />
        <CardContent>
          <Loading open={true} />
        </CardContent>
      </Card>
    )
  }
  if (!data) {
    return <h3>no data</h3>
  }

  return (
    <Card>
      <CardHeader title="使用量" subheader={`總數： ${total}`} />
      <CardContent>
        <div className={classes.chart}>
          <ResponsiveLine
            margin={{ top: 20, right: 40, bottom: 50, left: 30 }}
            animate={true}
            data={data}
            xScale={{
              type: 'time',
              format: '%Y-%m-%d',
              precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
              type: 'linear',
            }}
            axisLeft={{
              legend: '使用量',
              legendOffset: 12,
            }}
            axisBottom={{
              format: '%y-%m-%d',
              tickValues: 'every 1 days',
              legend: '日期',
              legendOffset: -12,
              tickRotation: 45,
            }}
            enablePointLabel={true}
            pointSize={8}
            pointBorderWidth={1}
            pointBorderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            useMesh={true}
            enableSlices={false}
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default RentRecordChart
