import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { xlsxExporter } from 'src/utils/utils'

export const OrderList = (props) => (
  <List
    exporter={xlsxExporter}
    sort={{ field: 'create_time', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <FunctionField
        label="使用者"
        render={(order) => (
          <Link to={`/user/${order.user_id}/show`}>{order.user.username}</Link>
        )}
      />
      <FunctionField
        label="店家"
        render={(order) => (
          <Link to={`/store/${order.store_id}/show`}>
            {order?.store?.store_name}
          </Link>
        )}
      />
      <DateField source="create_time" showTime />
      <TextField source="payment_status" />
      <TextField source="receipt_address" />
      <TextField source="note" />
      <BooleanField source="active" />
      <TextField source="state" />
    </Datagrid>
  </List>
)
