import { Edit, SimpleForm, TextInput } from 'react-admin'

export const MachineEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput label="id" source="id" />
        <TextInput disabled label="info" source="info" />
        <TextInput label="updated_time" source="updated_time" />
      </SimpleForm>
    </Edit>
  )
}
