import { Box } from '@mui/material'
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin'
import { xlsxExporter } from 'src/utils/utils'

const MachineLogPanel = () => {
  const record = useRecordContext()
  return <>to do</>
}

export const MachineList = (props) => {
  const { data: subscribingMachines, isLoading } = useGetList(
    'machine/subscribing',
    {}
  )

  return (
    <>
      {/* <Box>{JSON.stringify(subscribingMachines)}</Box> */}
      <Box>
        <br />
        <a href="https://console.cloud.google.com/logs/query?hl=zh-TW&project=my-web-project-65771">
          Logs
        </a>
      </Box>
      <List exporter={xlsxExporter} {...props}>
        <Datagrid
          bulkActionButtons={false}
          // rowClick="edit"
          expand={<MachineLogPanel />}
        >
          <TextField label="id" source="id" />
          <TextField label="info" source="info" />
          <DateField showTime label="updated_time" source="updated_time" />
          <FunctionField
            label="subscribing"
            render={(record) => {
              const isSubscribing = subscribingMachines?.find(
                (sub) => sub.id === record.id
              )
              return isSubscribing ? '✅' : '❌'
            }}
          />
          <FunctionField
            label="Logs"
            render={(record) => {
              return (
                <a
                  target="_blank"
                  href={`https://console.cloud.google.com/logs/query;query=labels.store_id%3D%22${record.id}%22?hl=zh-TW&project=my-web-project-65771`}
                  rel="noreferrer"
                >
                  Logs
                </a>
              )
            }}
          />
        </Datagrid>
      </List>
    </>
  )
}
