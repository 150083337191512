import liff from '@line/liff'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useLogin, useNotify } from 'react-admin'

const MyLoginPage = () => {
  const theme = useTheme()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState('stores')
  const login = useLogin()
  const notify = useNotify()

  const handleSubmit = (e) => {
    e.preventDefault()
    login({ username, password, model: type }).catch(() =>
      notify('帳號或密碼錯誤')
    )
  }

  const [profile, setProfile] = useState<any>(undefined)
  useEffect(() => {
    const login = async () => {
      // const decodedIDToken = liff.getDecodedIDToken()
      // if (isJwtExpired(decodedIDToken?.exp)) {
      //   console.log('isJwtExpired')
      //   await liff.logout()
      // }
      if (!liff.isLoggedIn()) {
        // set `redirectUri` to redirect the user to a URL other than the endpoint URL of your LIFF app.
        await liff.login({
          redirectUri: `${window.location.href}`,
        })
      }

      const newIDToken = liff.getIDToken()
      // await authProvider.socialLogin(newIDToken)
    }

    const init = async () => {
      try {
        try {
          await liff.init({
            liffId: process.env.REACT_APP_MY_LIFF_ID ?? '',
          })
        } catch (error) {
          console.error('initializeLiff Error: ', error)
          throw error
        }

        if (!liff.isLoggedIn()) {
          return
        }

        const newProfile = await liff.getProfile()
        setProfile(newProfile)

        // if (!authProvider.getToken()) {
        //   await login()
        // }
        // const token = authProvider.getToken()
        // if (token) {
        // } else {
        //   console.error('Unknown Error: token not found')
        // }
      } catch (error) {
        console.error('Unknown login error: ', error)
      }
    }

    // liff disabled
    // init()
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '16px',
        paddingTop: '32px',
        background: theme.palette.primary.main,
      }}
    >
      <Typography variant="h5" sx={{ py: 2, color: 'white' }}>
        uCup
      </Typography>

      {profile && (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={(profile?.pictureUrl as string) ?? ''}
              alt="Your avatar"
              sx={{ width: 48, height: 48 }}
            />
            <Typography
              display="inline"
              variant="h6"
              sx={{ pl: 2, color: 'white' }}
            >
              {profile?.displayName}
            </Typography>
          </div>
          <Typography variant="h5" sx={{ py: 2, color: 'white' }}>
            初次使用請先綁定店家
          </Typography>
        </div>
      )}
      <form
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={handleSubmit}
      >
        <TextField
          sx={{ py: 2 }}
          label="帳號"
          name="username"
          type="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          sx={{ py: 2 }}
          label="密碼"
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={isStore}
              onChange={(event) => setIsStore(event.target.checked)}
            />
          }
          label="商家"
        /> */}

        <Button type="submit" color="secondary" variant="contained">
          登入
        </Button>
      </form>
    </div>
  )
}

export default MyLoginPage
