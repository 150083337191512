// in src/Dashboard.js
import 'date-fns'

import AdminDashboard from './admin'
import BusinessDashboard from './business'
import ManagerDashboard from './manager'

export default function Dashboard({ permissions }) {
  if (permissions === 'admin') {
    return <AdminDashboard />
  } else if (permissions === 'store') {
    return <BusinessDashboard />
  } else if (permissions === 'manager') {
    return <ManagerDashboard />
  } else {
    return <>error: no permissions</>
  }
}
