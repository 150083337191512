import Grid from '@mui/material/Grid'
import { addDays, format } from 'date-fns'
import { useGetList } from 'react-admin'
import { chooseXY, sumYbyX } from 'src/utils/utils'

import RentRecordChart from '../charts/RentRecordChart'
import DateRangePicker from '../components/DateRangePicker'
import useTimeRange from '../hooks/useTimeRange'
import { truncateHighWallDate } from '../utils'

function AdminDashboard() {
  const { range, rangeMode, handleRangeChange, handleRangeModeChange } =
    useTimeRange()

  const rentCountByDay = useGetList('record/rent_count_by_day', {
    pagination: { page: 1, perPage: 365 },
    filter: {
      date_gte: format(range.start, 'yyyy-MM-dd'),
      date_lte: format(addDays(range.end, 0), 'yyyy-MM-dd'),
    },
  })
  const rentCountXYRecord = chooseXY(
    truncateHighWallDate(rentCountByDay.data, 'datetime'),
    'datetime',
    'rent_count'
  )

  return (
    <div style={{ padding: 12 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12}>
          <DateRangePicker
            range={range}
            rangeMode={rangeMode}
            onRangeChange={handleRangeChange}
            onRangeModeChange={handleRangeModeChange}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <RentRecordChart
            isLoading={rentCountByDay.isLoading}
            error={rentCountByDay.error}
            data={[
              {
                id: '總數',
                data: sumYbyX(rentCountXYRecord),
              },
            ]}
            total={rentCountXYRecord.reduce(
              (sum, xyRecord) => sum + xyRecord.y,
              0
            )}
          />
        </Grid>

        <a
          target="_blank"
          rel="noreferrer"
          href="https://rhodanthe1116.grafana.net/d/DPQ8_xAnz/tu-biao?orgId=1"
        >
          數據統計\分析
        </a>
      </Grid>
    </div>
  )
}

export default AdminDashboard
