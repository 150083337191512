import { format } from 'date-fns'
import {
  ArrayField,
  Datagrid,
  DateField,
  Edit,
  FunctionField,
  Labeled,
  List,
  ListButton,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin'
import SmallImageField from 'src/Components/SmallImageField'
import { xlsxExporter } from 'src/utils/utils'

const UserTitle = () => {
  return <span>使用者</span>
}

/*
For nested field like social connections, you need to extend query in hasura dataProvider.
*/
export const UserList = (props) => (
  <List
    exporter={xlsxExporter}
    title={<UserTitle />}
    rowsPerPageOptions={[10, 25, 50, 100]}
    filters={[
      <TextInput
        key="Search"
        label="Search"
        source="username,social_connections#access_token,id"
        alwaysOn
      />,
    ]}
    {...props}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <SmallImageField source="picture_url" />
      <TextField source="username" />
      <TextField source="email" />
      <TextField source="phone" />
      <ArrayField sortable={false} label="綁定帳號" source="social_connections">
        <Datagrid header={<></>} bulkActionButtons={false}>
          <TextField source="access_token" />
        </Datagrid>
      </ArrayField>
      <TextField source="id" />
      <FunctionField
        label="停權或停用"
        source="active"
        render={(record) => (record?.active ? '➖' : '🔒')}
      />
      <DateField source="create_time" showTime />
      {/* <DateField source="update_time" showTime/> */}
      {/* <TextField source="email" /> */}
      {/* <TextField source="is_staff" /> */}
      {/* <TextField source="state" /> */}
      {/* <TextField source="uuid" /> */}
    </Datagrid>
  </List>
)

const UserEditActions = () => (
  <TopToolbar>
    <ListButton />
  </TopToolbar>
)

export const UserEdit = ({ ...props }) => {
  const { permissions } = usePermissions()
  return (
    <Edit actions={<UserEditActions />} {...props}>
      <SimpleForm spacing={1}>
        <Labeled label="id">
          <TextField source="id" />
        </Labeled>

        <Labeled label="頭像">
          <SmallImageField source="picture_url" />
        </Labeled>
        <Labeled label="username">
          <TextField source="username" />
        </Labeled>
        <Labeled label="email">
          <TextField source="email" />
        </Labeled>
        <Labeled label="phone">
          <TextField source="phone" />
        </Labeled>
        <Labeled label="綁定帳號">
          <ArrayField source="social_connections">
            <Datagrid bulkActionButtons={false} header={<></>}>
              <TextField source="access_token" />
              <TextField source="provider" />
            </Datagrid>
          </ArrayField>
        </Labeled>

        {permissions === 'admin' ? (
          <>
            <br />
            <NumberInput label="租借上限" source="rent_limit" />
          </>
        ) : (
          <Labeled label="租借上限">
            <TextField source="rent_limit" />
          </Labeled>
        )}

        <Labeled label="停權或停用">
          <FunctionField
            source="active"
            render={(record) => (record?.active ? '➖' : '🔒')}
          />
        </Labeled>
        <Labeled label="create_time">
          <DateField source="create_time" showTime />
        </Labeled>
        <Labeled label="update_time">
          <DateField source="update_time" showTime />
        </Labeled>
        {/* <TextField source="is_staff" /> */}
        {/* <TextField source="state" /> */}

        <Labeled label="借用紀錄">
          <ArrayField source="rents">
            <Datagrid bulkActionButtons={false}>
              <DateField label="時間" source="create_time" showTime />
              <TextField label="店家" source="store.store_name" />
              <FunctionField
                label="歸還店家"
                source="returns"
                render={({ rent_type, returns }: any) =>
                  rent_type === 'Self'
                    ? '➖'
                    : returns?.length > 0 && returns[0].store.store_name
                }
              />
              <FunctionField
                label="歸還時間"
                source="returns"
                render={({ rent_type, returns }: any) =>
                  rent_type === 'Self'
                    ? '➖'
                    : returns?.length > 0 &&
                      format(
                        new Date(returns[0].create_time),
                        'yyyy-MM-dd HH:mm'
                      )
                }
              />
              <TextField label="使用途徑" source="provider" />
              <TextField label="使用類型" source="rent_type" />
            </Datagrid>
          </ArrayField>
        </Labeled>

        <Labeled label="點數紀錄">
          <ArrayField source="bonuses">
            <Datagrid bulkActionButtons={false}>
              <DateField label="時間" source="create_time" showTime />
              <DateField label="過期" source="expired_time" showTime />
              <TextField label="點數" source="change" />
              <TextField label="備註" source="info" />
            </Datagrid>
          </ArrayField>
        </Labeled>
      </SimpleForm>
    </Edit>
  )
}
