import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Alert, Box, Container, Paper, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import React, { useState } from 'react'
import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FunctionField,
  Labeled,
  NullableBooleanInput,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreate,
  useDelete,
  useNotify,
  usePermissions,
  useRefresh,
  useShowController,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { Role } from 'src/authProvider'
import RemoteImageInput from 'src/Components/RemoteImageInput'
import SmallImageField from 'src/Components/SmallImageField'

import { QRCodeGenerator, Store立牌 } from './qrcode'

interface EditCupNumRequest {
  note: string
  clean_number: number
  dirty_number: number
}
interface EditCupNumFormInput {
  noteInput: string
  addCleanInput: string
  addDirtyInput: string
}
function editCupNumFormTransfrom(
  input: EditCupNumFormInput
): EditCupNumRequest {
  return {
    note: input.noteInput,
    clean_number: Number(input.addCleanInput),
    dirty_number: Number(input.addDirtyInput),
  }
}

export const StoreEdit = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { permissions } = usePermissions()

  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props)
  const [addCleanInput, setAddCleanInput] = React.useState('')
  const [addDirtyInput, setAddDirtyInput] = React.useState('')
  const [noteInput, setNoteInput] = React.useState('')

  const [create, createMutation] = useCreate()
  function submitEditCupNumForm() {
    const data = editCupNumFormTransfrom({
      addCleanInput,
      addDirtyInput,
      noteInput,
    })

    create(
      `stores/cup_num?store_id=${record?.id}`,
      {
        data: data,
      },
      {
        onSuccess: () => {
          notify('成功！')
          refresh()
          setAddCleanInput('')
          setAddDirtyInput('')
          setNoteInput('')
        },
        onError: (error) => {
          notify(`使用錯誤： ${JSON.stringify(error)} `, {
            type: 'warning',
          })
        },
      }
    )
  }

  const [editCupNumOpen, setEditCupNumOpen] = useState(false)
  const [selectedCupNum, setSelectedCupNum] = useState<any>()
  const selectedCupTypeId = selectedCupNum?.cup_type_id

  const [deleteOne, { isLoading, error }] = useDelete()
  const handleDeleteStaffClick = (record) => {
    if (!record.id) {
      notify('error')
      return
    }
    deleteOne(
      'store_management',
      { id: record.id },
      {
        onSuccess: () => {
          notify('刪除成功！')
          refresh()
        },
      }
    )
  }

  const [storeManagementDialogOpen, setStoreManagementDialogOpen] =
    useState(false)

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          p: 0,
        }}
      >
        <Edit title={' '} redirect={false} {...props} component="div">
          <SimpleForm
            sx={{
              px: {
                xs: 0,
                md: 2,
              },
            }}
            toolbar={
              <Toolbar>
                <SaveButton />
              </Toolbar>
            }
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={8}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Paper
                  sx={{
                    width: '100%',
                    padding: 2,
                  }}
                >
                  <Stack direction="column">
                    <FunctionField
                      render={(record) => {
                        if (record?.active === false) {
                          return (
                            <Alert sx={{ marginBottom: 4 }} severity="warning">
                              此店家未啟用，無法使用借還等功能
                            </Alert>
                          )
                        }
                        return ''
                      }}
                    />
                    <TextInput
                      label="名稱"
                      source="store_name"
                      validate={required()}
                      helperText={false}
                      sx={{
                        mb: 1,
                      }}
                    />

                    <Stack
                      direction={{
                        xs: 'column',
                        md: 'row',
                      }}
                      sx={{
                        mt: 1,
                      }}
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2}>
                        {permissions === 'admin' && (
                          <Labeled>
                            <TextField source="id" />
                          </Labeled>
                        )}
                        <Labeled
                          sx={{
                            mb: 1,
                          }}
                        >
                          <TextField label="帳號" source="phone" />
                        </Labeled>
                      </Stack>

                      <RemoteImageInput source="image_url" />
                    </Stack>

                    <Typography variant="body1" gutterBottom>
                      杯子庫存
                    </Typography>
                    {permissions === 'admin' && (
                      <ReferenceInput
                        label="預設杯款"
                        source="default_cup_type_id"
                        reference="cup_type"
                      >
                        <AutocompleteInput
                          size="small"
                          sx={{
                            mt: 1,
                          }}
                          optionText="name"
                          label="預設杯款"
                          // disabled={selectedCupType !== undefined}
                          // defaultValue={selectedCupType}
                        />
                      </ReferenceInput>
                    )}
                    <ArrayField label="杯子數" source="cup_nums">
                      <Datagrid
                        bulkActionButtons={false}
                        rowClick={(_a, _b, record) => {
                          setSelectedCupNum(record)
                          setEditCupNumOpen(true)
                          return false
                        }}
                        sort={{ field: 'cup_type_id', order: 'ASC' }}
                      >
                        <SmallImageField
                          label="杯款"
                          source="cup_type.image_url"
                        />
                        <TextField label="杯款名" source="cup_type.name" />
                        <TextField label="乾淨杯子數" source="clean_number" />
                        <TextField label="待回收杯子數" source="dirty_number" />
                      </Datagrid>
                    </ArrayField>
                    {permissions === 'admin' && (
                      <Button
                        sx={{
                          mt: 1,
                          ml: 'auto',
                          display: 'block',
                        }}
                        onClick={() => setEditCupNumOpen(true)}
                      >
                        + 新增杯款
                      </Button>
                    )}

                    <br />
                    <ArrayField
                      label="物流收送紀錄"
                      source="cup_num_records"
                      fullWidth
                    >
                      <Datagrid bulkActionButtons={false}>
                        <FunctionField
                          label="時間"
                          render={(record) => {
                            return format(
                              new Date(record.create_time),
                              'MM/dd HH:mm'
                            )
                          }}
                        />
                        <SmallImageField
                          label="杯款"
                          source="cup_num.cup_type.image_url"
                        />
                        {/* <TextField label="杯款" source="cup_num.cup_type.name" /> */}
                        <TextField
                          label="可使用杯"
                          source="clean_number_change"
                        />
                        <TextField
                          label="待回收杯"
                          source="dirty_number_change"
                        />
                        <TextField label="備註" source="note" />
                      </Datagrid>
                    </ArrayField>
                    <Button
                      sx={{
                        mt: 1,
                        ml: 'auto',
                        display: 'block',
                      }}
                      component={Link}
                      to={`/cup_num_record?filter=%7B%22store_id%22%3A${record?.id}%7D`}
                    >
                      查看全部
                    </Button>
                  </Stack>
                </Paper>

                <Paper
                  sx={{
                    width: '100%',
                    padding: 2,

                    mt: 2,
                  }}
                >
                  <Stack>
                    <TextInput label="email" source="email" />
                    <TextInput
                      label="詳細資訊"
                      source="info"
                      multiline
                      fullWidth
                    />
                    <TextInput label="地址" source="address" />

                    <Stack direction="row" spacing={1}>
                      <TextInput label="緯度" source="latitude" />
                      <TextInput label="經度" source="longitude" />
                    </Stack>
                    <TextInput label="Google Map 連結" source="website" />
                    <Stack direction="row" spacing={1}>
                      <SelectInput
                        source="store_type"
                        choices={[
                          { id: 'store', name: '店家' },
                          { id: 'box', name: '歸還箱' },
                        ]}
                      />
                      <NumberInput label="顯示順序" source="display_order" />
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  mt: {
                    xs: 2,
                    md: 0,
                  },
                }}
              >
                <Paper
                  sx={{
                    p: 2,
                  }}
                >
                  <Stack>
                    <Typography variant="h6">狀態</Typography>
                    <BooleanInput
                      label="啟用中"
                      helperText="若未啟用（封存狀態），則無法在此店家借還"
                      source="active"
                    />
                  </Stack>
                </Paper>

                <Paper
                  sx={{
                    width: '100%',
                    padding: 2,

                    mt: 2,
                  }}
                >
                  <Stack>
                    <Typography variant="h6">借還設定</Typography>
                    <Stack direction="row" spacing={1}>
                      <BooleanInput
                        helperText={false}
                        label="可借"
                        source="rent_available"
                      />
                      <BooleanInput
                        helperText={false}
                        label="可還"
                        source="return_available"
                      />
                    </Stack>
                    <BooleanInput
                      label="借用時是否需要杯子id"
                      source="cup_id_rent_verified"
                      helperText={'注意杯子id需事先登入在系統'}
                    />
                    <BooleanInput
                      label="歸還時是否需要杯子id"
                      source="cup_id_return_verified"
                      helperText={false}
                      sx={{
                        mb: 0,
                        pb: 0,
                      }}
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        mb: 1,
                        mt: 0,
                        whiteSpace: 'pre-line',
                        fontSize: '0.75rem',
                      }}
                    >
                      {`開啟後，杯子id鎖N小時，避免重複歸還
須開啟店家的"歸還時是否需要杯子id" (cup_id_return_verified)
預設情況為關閉店家的"借用時是否需要杯子id" (cup_id_rent_verified)
N小時內：
Ex1: 借杯(?) > 還杯(cup1) > 借杯(?) > 還杯(cup1) --- X
Ex2: 借杯(?) > 還杯(cup1) > 借杯(?) > 還杯(cup2) --- OK
    `}
                    </Typography>

                    {permissions === 'admin' && (
                      <BooleanInput
                        label="歸還時是否需要拍照"
                        source="return_photo_needed"
                        helperText="歸還時，消費者必須要拍包含杯子與歸還點的照片。上傳的照片可在後台單個借用紀錄或歸還紀錄裡面看到"
                      />
                    )}
                    <BooleanInput
                      label="是否需要定位"
                      source="geolocation_needed"
                    />

                    <NullableBooleanInput
                      fullWidth
                      nullLabel="預設"
                      label="允許杯數負數"
                      source="allow_minus_cup_num"
                      helperText="如果店家有設定會優先採用店家，如果店家沒有設定會採用全局設定"
                    />

                    <FunctionField
                      label="允許杯數負數全局設定"
                      source="store_managements"
                      render={(record) => {
                        const staffs = record?.store_managements?.map(
                          (s) => s.staff
                        )
                        if (staffs.length !== 1) {
                          return (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              全局設定衝突
                            </Typography>
                          )
                        }
                        console.log({ staffs })
                        const staff = staffs[0]
                        const setting = staffs.find(
                          (s) =>
                            s.allow_minus_cup_num !== null ||
                            s.allow_minus_cup_num !== undefined
                        )
                        let label = ''
                        if (setting === undefined) {
                          label = '無設定'
                        }
                        label = setting?.allow_minus_cup_num ? '允許' : '不允許'
                        return (
                          <>
                            全局設定：{label}
                            <Button component={Link} to={`/staff/${staff.id}`}>
                              修改
                            </Button>
                          </>
                        )
                      }}
                    />
                  </Stack>
                </Paper>

                {permissions === 'admin' && (
                  <Paper
                    sx={{
                      width: '100%',
                      padding: 2,
                      mt: 2,
                    }}
                  >
                    <Stack>
                      <Typography variant="h6">權限</Typography>
                      <ArrayField label="權限" source="store_managements">
                        <Datagrid bulkActionButtons={false}>
                          <TextField label="人員" source="staff.username" />
                          <DateField
                            label="時間"
                            source="create_time"
                            showTime
                          />
                          {/* dropdown action*/}
                          <FunctionField
                            label="Action"
                            render={(record) => {
                              return (
                                <StaffMoreAction
                                  record={record}
                                  onDeleteClick={() =>
                                    handleDeleteStaffClick(record)
                                  }
                                />
                              )
                            }}
                          />
                        </Datagrid>
                        <Button
                          sx={{
                            mt: 1,
                          }}
                          onClick={() => setStoreManagementDialogOpen(true)}
                        >
                          + 新增
                        </Button>
                      </ArrayField>

                      {permissions === Role.ADMIN && record?.id && (
                        <StoreManagementCreateDialog
                          open={storeManagementDialogOpen}
                          onClose={() => setStoreManagementDialogOpen(false)}
                          store={record}
                          {...props}
                        />
                      )}
                    </Stack>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </SimpleForm>
        </Edit>

        {/* <Edit redirect={false} {...props}>
        <SimpleForm>
         
        </SimpleForm>
      </Edit> */}

        <CupNumEditDialog
          record={record}
          editCupNumOpen={editCupNumOpen}
          setEditCupNumOpen={setEditCupNumOpen}
          selectedCupNum={selectedCupNum}
          setSelectedCupNum={setSelectedCupNum}
          selectedCupTypeId={selectedCupTypeId}
        />

        <Paper
          sx={{
            mt: 2,
            p: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            立牌 QRCode 產生器
          </Typography>
          <Box
            style={{
              maxWidth: '80vw',
              overflow: 'auto',
            }}
          >
            <p>如字型未正確顯示，請重新整理</p>
            <Store立牌 storeId={record?.id} storeName={record?.store_name} />
          </Box>
          <div style={{ margin: 16, marginTop: 48 }}>
            <QRCodeGenerator storeId={record?.id} />
          </div>
        </Paper>
      </Container>
    </>
  )
}

const CupNumEditDialog = ({
  record,
  editCupNumOpen,
  setEditCupNumOpen,
  selectedCupNum,
  setSelectedCupNum,
  selectedCupTypeId,

  ...props
}) => {
  const inputCommonProps = {
    variant: 'outlined',
    size: 'medium',
    InputLabelProps: {
      shrink: true,
    },
  } as const
  const notify = useNotify()
  const refresh = useRefresh()

  return (
    <Dialog
      open={editCupNumOpen}
      onClose={() => {
        setEditCupNumOpen(false)
        setSelectedCupNum(undefined)
      }}
    >
      {editCupNumOpen && (
        <Create
          sx={{ mb: 1 }}
          resource="stores/cup_num"
          redirect={false}
          mutationOptions={{
            onSuccess: () => {
              notify('成功！')
              refresh()
            },
          }}
        >
          <SimpleForm
            toolbar={
              <Toolbar
                sx={{
                  '&.RaToolbar-mobileToolbar': { position: 'relative' },
                }}
              >
                <SaveButton />
                {/* <DeleteCupNumButton id={selectedCupNum} /> */}
              </Toolbar>
            }
          >
            <Typography variant="h6" gutterBottom>
              {selectedCupNum ? '編輯' : '新增'}杯款
            </Typography>
            <TextInput
              sx={{ display: 'none' }}
              disabled
              label="店家id"
              source="store_id"
              defaultValue={record?.id}
            />

            <ReferenceInput source="cup_type_id" reference="cup_type">
              <AutocompleteInput
                sx={{
                  display: selectedCupNum ? 'none' : 'block',
                }}
                optionText="name"
                disabled={selectedCupTypeId !== undefined}
                defaultValue={selectedCupTypeId}
              />
            </ReferenceInput>
            <Typography>{selectedCupNum?.cup_type?.name}</Typography>

            <SmallImageField
              label="杯款"
              size="medium"
              record={selectedCupNum}
              source="cup_type.image_url"
              fieldSx={{
                mt: 2,
                marginBottom: 4,
              }}
            />

            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={6}>
                <NumberInput
                  {...inputCommonProps}
                  helperText={false}
                  source="clean_number"
                  label="✨可使用"
                  type="number"
                  format={(change) =>
                    (selectedCupNum?.clean_number ?? 0) + Number(change)
                  }
                  parse={(v) => Number(v) - (selectedCupNum?.clean_number ?? 0)}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  {...inputCommonProps}
                  helperText={false}
                  source="clean_number"
                  label="調整值+/-"
                  type="number"
                  defaultValue={0}
                />
              </Grid>

              <Grid item xs={6}>
                <NumberInput
                  {...inputCommonProps}
                  helperText={false}
                  source="dirty_number"
                  label="♻️待回收"
                  type="number"
                  format={(change) =>
                    (selectedCupNum?.dirty_number ?? 0) + Number(change)
                  }
                  parse={(v) => Number(v) - (selectedCupNum?.dirty_number ?? 0)}
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={6}>
                <NumberInput
                  {...inputCommonProps}
                  helperText={false}
                  source="dirty_number"
                  label="調整值+/-"
                  type="number"
                  defaultValue={0}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  fullWidth
                  source="note"
                  label="備註"
                  type="text"
                  size="medium"
                  variant="outlined"
                  multiline
                />
              </Grid>
            </Grid>
          </SimpleForm>
        </Create>
      )}
    </Dialog>
  )
}

const StoreManagementCreateDialog = ({ open, onClose, store, ...props }) => {
  const refresh = useRefresh()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Create
        {...props}
        sx={{ mt: 2, mb: 16 }}
        resource="store_management"
        redirect={false}
        mutationOptions={{
          onSuccess: () => {
            refresh()
            onClose()
          },
        }}
      >
        <SimpleForm
          toolbar={
            <Toolbar
              sx={{
                '&.RaToolbar-mobileToolbar': { position: 'relative' },
              }}
            >
              <SaveButton />
            </Toolbar>
          }
        >
          <Typography variant="h6" sx={{ py: 3 }}>
            新增權限
          </Typography>
          <TextInput
            disabled
            sx={{
              display: 'none',
            }}
            label="店家id"
            source="store_id"
            defaultValue={store.id}
          />
          <ReferenceInput source="staff_id" reference="staff">
            <AutocompleteInput label="人員" />
          </ReferenceInput>
          <DateTimeInput
            disabled
            sx={{
              display: 'none',
            }}
            label="創立時間"
            source="create_time"
            defaultValue={new Date().toISOString()}
          />
        </SimpleForm>
      </Create>
    </Dialog>
  )
}
function StaffMoreAction({ record, onDeleteClick }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Link to={`/staff/${record.staff.id}`}>View</Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            onDeleteClick()
            // Add your delete logic here
          }}
        >
          <DeleteIcon />
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

function DeleteCupNumButton({ id }: { id?: number }) {
  const [deleteOne, { isLoading, error }] = useDelete()
  const handleClick = () => {
    deleteOne('cup_num', { id: id })
  }
  if (!id) {
    return null
  }
  if (error) {
    return <Button disabled={true}>Error</Button>
  }
  return (
    <Button disabled={isLoading} onClick={handleClick}>
      Delete
    </Button>
  )
}
