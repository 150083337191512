import liff from '@line/liff'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
// components
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { useCreate, useGetIdentity, useGetOne, useNotify } from 'react-admin'
import { useNavigate } from 'react-router-dom'

import CupCard from '../Components/CupCard'
import Scanner from '../Components/ZXingScanner/ZXingScanner'
import { determineProvider, vibrate } from '../utils/utils'

const PREFIX = 'Business'

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
  root2: `${PREFIX}-root2`,
  root3: `${PREFIX}-root3`,
  root4: `${PREFIX}-root4`,
  business: `${PREFIX}-business`,
  cupCard: `${PREFIX}-cupCard`,
  form: `${PREFIX}-form`,
  idInputRow: `${PREFIX}-idInputRow`,
  scannerColumn: `${PREFIX}-scannerColumn`,
  actionRow: `${PREFIX}-actionRow`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.business}`]: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '70vh',
    marginBottom: '10vh',
  },

  [`& .${classes.cupCard}`]: {
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    flex: '1 0 auto',
  },

  [`& .${classes.idInputRow}`]: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },

  [`& .${classes.scannerColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.actionRow}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

// others

let provider: string | undefined = undefined

function Business() {
  const navigate = useNavigate()

  const { data } = useGetIdentity()
  const storeQuery = useGetOne('stores', {
    id: data?.id,
  })
  const cupNums = storeQuery.data?.cup_nums

  const [userId, setUserId] = useState('')
  const [cupId, setCupId] = useState('')
  const [selfRentOrReturn, setSelfRentOrReturn] = useState('rent')
  const cupType = selfRentOrReturn === 'Self' ? 'Self' : 'uCup'
  const [userIdInputError, setInputError] = useState(false)

  const [scanning, setScanning] = useState(false)

  const lineScanCode = () => {
    if (liff && liff?.scanCode) {
      if (!liff?.isInClient()) {
        notify('要使用Line掃描器，請透過Line官方帳號開啟')
        return
      }
      liff?.scanCode().then((result) => {
        onDetected(result.value)
      })
    }
  }

  const onDetected = (newDetectResult) => {
    setScanning(false)

    vibrate()

    provider = determineProvider(newDetectResult)

    if (provider === 'invalid') {
      notify(`${newDetectResult} 條碼格式不符或掃描失敗，請重新掃描！`)
      return
    } else if (provider === 'NTU Mail') {
      newDetectResult = newDetectResult.slice(0, -1)
    }

    setUserId(newDetectResult)
  }

  const notify = useNotify()
  const [create, { isLoading }] = useCreate()
  const doRent = () =>
    create(
      'record/do_rent',
      {
        data: {
          user_id: provider === 'NTU Mail' ? userId.toUpperCase() : userId,
          provider: provider,
          cup_type: cupType,
          change: 1,
          cup_id: cupId === '' ? undefined : cupId,
        },
      },
      {
        onSuccess: () => {
          notify(cupType === 'Self' ? '自備成功！' : '租借成功！')
          storeQuery.refetch()
        },
        onError: (error) =>
          notify(`使用錯誤： ${JSON.stringify(error)} `, { type: 'warning' }),
      }
    )
  const doReturn = () =>
    create(
      'record/do_return',
      {
        data: {
          user_id: provider === 'NTU Mail' ? userId.toUpperCase() : userId,
          provider: provider,
          change: 1,
          cup_type: cupType,
          cup_id: cupId === '' ? undefined : cupId,
        },
      },
      {
        onSuccess: () => {
          notify('歸還成功！')
          storeQuery.refetch()
        },
        onError: (error) =>
          notify(`歸還錯誤： ${JSON.stringify(error)} `, { type: 'warning' }),
      }
    )

  const handleComfirm = async () => {
    // Display scan result and prompt
    provider = determineProvider(userId)

    if (cupId === '' && provider === 'invalid') {
      setInputError(true)
      return
    }

    if (cupId !== '') {
      provider = 'Normal'
    }

    setInputError(false)
    if (selfRentOrReturn === 'Self') {
      doRent()
    } else if (selfRentOrReturn === 'rent') {
      doRent()
    } else if (selfRentOrReturn === 'return') {
      doReturn()
    }

    setUserId('')
    setCupId('')
  }

  return (
    <Root className={classes.business}>
      <div className={classes.cupCard}>
        <CupCard loading={storeQuery.isLoading} cupNums={cupNums} />
      </div>

      {/* <Typography variant="h5" gutterBottom>
        掃描
      </Typography> */}

      <div className={classes.form}>
        <div className={classes.idInputRow}>
          <TextField
            InputLabelProps={{ shrink: true }}
            error={userIdInputError}
            helperText={'請掃描使用者的QR Code或手動輸入編號'}
            required
            id="user-id"
            label="使用者編號"
            color="primary"
            value={userId}
            onChange={(event) => {
              setUserId(event.target.value)
            }}
          />
          <div className={classes.scannerColumn}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setScanning(true)
              }}
              startIcon={<CameraAltIcon />}
              classes={{
                root: classes.root2,
              }}
            >
              掃描
            </Button>
            <Button
              sx={{
                color: 'green',
                // color: theme.palette.getContrastText(green[800]),
                // color: green[800],
                // backgroundColor: green[600],
                // '&:hover': {
                //   backgroundColor: green[700],
                // },
              }}
              size="small"
              onClick={lineScanCode}
              startIcon={<CameraAltIcon />}
              classes={{
                root: classes.root4,
              }}
            >
              Line
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                if (!('NDEFReader' in window)) {
                  notify('Web NFC 功能無法使用\n')
                  return 0
                }
                const ndef = new NDEFReader()
                const ctlr = new AbortController()
                ndef.addEventListener(
                  'reading',
                  (event) => {
                    ctlr.abort()
                    const { serialNumber } = event as NDEFReadingEvent
                    onDetected(serialNumber.replace(/:/g, '').toUpperCase())
                  },
                  { once: true }
                )
                ndef.scan({ signal: ctlr.signal }).catch((err) => notify(err))
              }}
              classes={{
                root: classes.root2,
              }}
            >
              感應
            </Button>
          </div>
        </div>

        <div className={classes.idInputRow}>
          <TextField
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
            id="cup-id"
            label="杯子id"
            color="primary"
            value={cupId}
            onChange={(event) => {
              setCupId(event.target.value)
            }}
          />
          <div className={classes.scannerColumn}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setScanning(true)
              }}
              startIcon={<CameraAltIcon />}
              classes={{
                root: classes.root2,
              }}
            >
              掃描
            </Button>
            <Button
              sx={{
                color: 'green',
                // color: theme.palette.getContrastText(green[800]),
                // color: green[800],
                // backgroundColor: green[600],
                // '&:hover': {
                //   backgroundColor: green[700],
                // },
              }}
              size="small"
              onClick={lineScanCode}
              startIcon={<CameraAltIcon />}
              classes={{
                root: classes.root4,
              }}
            >
              Line
            </Button>
          </div>
        </div>

        <FormControl component="fieldset">
          <FormLabel component="legend">使用類型</FormLabel>
          <RadioGroup
            aria-label="use-type"
            name="use-type1"
            value={selfRentOrReturn}
            onChange={(event) => setSelfRentOrReturn(event.target.value)}
          >
            <div>
              <FormControlLabel
                value="rent"
                control={
                  <Radio
                    color="default"
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                    }}
                  />
                }
                label="借用uCup"
              />
              <FormControlLabel
                value="return"
                control={
                  <Radio
                    color="default"
                    classes={{
                      root: classes.root,
                      checked: classes.checked,
                    }}
                  />
                }
                label="歸還uCup"
              />
            </div>
          </RadioGroup>
        </FormControl>
      </div>

      <Dialog fullScreen open={scanning} onClose={() => setScanning(false)}>
        <Scanner
          facingMode="environment"
          onDetected={onDetected}
          onClose={() => setScanning(false)}
        />
      </Dialog>

      <div className={classes.actionRow}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleComfirm}
          disabled={isLoading}
          classes={{
            root: classes.root2,
          }}
        >
          送出
        </Button>
      </div>
    </Root>
  )
}

export default Business
