import _get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import { stringify } from 'query-string'

import { getProfile } from '../auth/utils'
import { fetchJson } from '../utils/fetchJson'

// const testProduction = true ? process.env.NODE_ENV === 'development' : false
const apiUrl = process.env.REACT_APP_DATA_PROVIDER_URL

const httpClient = (apiUrl, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = localStorage.getItem('token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchJson(apiUrl, options)
}

// const httpClient = fetchUtils.fetchJson;

const diff = (current, previous) => {
  return pickBy(current, (v, k) => {
    return JSON.stringify(previous[k]) !== JSON.stringify(v)
  })
}

function getFormData(object) {
  const formData = new FormData()
  Object.keys(object).forEach((key) => {
    const value = object[key]

    if (key !== 'id' && key !== 'update_time' && key !== 'create_time') {
      formData.append(key, value)
    }
  })
  return formData
}

const highWallEndpoint = {
  user: 'users',
  social_connection: 'users/all_social_users',
  rent: 'record/rents',
  return: 'record/returns',
  store: 'stores',
  product: 'products',
  bonus: 'bonuses',
}
const getHighWallEndpoint = (resource) => {
  if (resource in highWallEndpoint) {
    return highWallEndpoint[resource]
  } else {
    return resource
  }
}

const dataProvider = {
  getList: (resource, params) => {
    const query = stringify(params.filter)
    resource = getHighWallEndpoint(resource)
    const url = `${apiUrl}/${resource}?${query}`

    return httpClient(url).then(({ json }) => {
      // json = json.splice(0, 80);

      if (resource === 'record/get_first_users_in_store') {
        json = json.filter((d) => d.create_time[0] >= '2021-10-08')
      }

      const field = params.sort.field
      json.sort((a, b) => {
        return _get(b, field) - _get(a, field)
      })

      json = json.map((obj, index) => {
        if (obj.id === undefined) {
          obj.id = index
        }
        return obj
      })
      return {
        data: json,
        total: json.length,
      }
    })
  },

  getOne: (resource, params) => {
    if (resource === 'profile') {
      // const storedProfile = localStorage.getItem("profile");
      // if (storedProfile) {
      //     return Promise.resolve({
      //         data: JSON.parse(storedProfile),
      //     });
      // }
      const profile = getProfile()
      return httpClient(`${apiUrl}/stores/${profile.id}`).then(({ json }) => {
        json.id = 'profile'
        localStorage.setItem('profile', JSON.stringify(json))
        return {
          data: json,
        }
      })
    }
    if (resource === 'record/rents/statistics') {
      return httpClient(`${apiUrl}/${resource}`).then(({ json }) => {
        return {
          data: { id: 0, json },
        }
      })
    }

    resource = getHighWallEndpoint(resource)
    console.log({ resource, params })
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      return {
        data: json,
      }
    })
  },

  getMany: (resource) => {
    resource = getHighWallEndpoint(resource)

    // const query = {
    //     filter: JSON.stringify({ id: params.ids }),
    // };
    const url = `${apiUrl}/${resource}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    resource = getHighWallEndpoint(resource)

    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ json }) => ({
      data: json,
      total: json.length,
    }))
  },

  update: (resource, params) => {
    const data = diff(params.data, params.previousData)

    if (resource === 'profile') {
      const profile = getProfile()
      return httpClient(`${apiUrl}/stores/${profile.id}`, {
        method: 'POST',
        body: JSON.stringify(data),
      }).then(({ json }) => {
        json.id = 'profile'
        return {
          data: json,
        }
      })
    }

    resource = getHighWallEndpoint(resource)

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }))
  },

  updateMany: (resource, params) => {
    resource = getHighWallEndpoint(resource)

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) => {
    resource = getHighWallEndpoint(resource)

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: {
        id: json?.result?.rent?.id ?? 123,
        ...json,
      },
    }))
  },

  createMany: (resource, params) => {
    resource = getHighWallEndpoint(resource)

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    }))
  },

  delete: (resource, params) => {
    resource = getHighWallEndpoint(resource)

    const query = {}
    const endpoint = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`

    // if (resource === 'stores') {
    //     query = {
    //         store_id: params.id,
    //     };
    //     params.id = '';
    //     endpoint = `${apiUrl}/${resource}?${stringify(query)}`
    // }

    return httpClient(endpoint, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },

  deleteMany: (resource, params) => {
    resource = getHighWallEndpoint(resource)

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl} /${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.ids),
    }).then(({ json }) => ({ data: json }))
  },

  uploadFile: async (rawFile: File) => {
    const url = `${apiUrl}/storage/files`

    const formdata = new FormData()
    formdata.append('file', rawFile)
    return httpClient(url, {
      method: 'POST',
      body: formdata,
    }).then(({ json }) => json.result)
  },
  fetch: (path, options = {}) => {
    const url = `${apiUrl}${path}`
    return httpClient(url, options)
  },
}

export default dataProvider
