import { useEffect, useState } from 'react'

const useLiff = () => {
  const [liff, setLiff] = useState<any>(undefined)
  useEffect(() => {
    // if app is running by Cypress, then uses the liff mock object
    if ((window as any)?.Cypress) {
      setLiff((window as any)?.Cypress.liffMock)
      return
    }

    const script = document.createElement('script')
    script.setAttribute('id', 'liff1')
    script.src = 'https://static.line-scdn.net/liff/edge/2.1/sdk.js'
    script.async = true
    script.onload = () => {
      ;(window as any)?.liff
        .init({
          liffId: process.env.REACT_APP_MY_LIFF_ID,
        })
        .then(() => {
          setLiff((window as any)?.liff)
        })
        .catch((error) => {
          alert(`initializeLiff Error ${error.toString()}`)
          console.log('initializeLiff Error', error)
        })
    }
    document.body.appendChild(script)
  }, [])

  return liff
}

export default useLiff
