import { gql, useQuery } from '@apollo/client'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import MuiList from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library'
import { endOfToday, format, startOfToday, subDays } from 'date-fns'
import { useEffect, useState } from 'react'
import {
  Create,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  List,
  ListButton,
  required,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { vibrate, xlsxExporter } from 'src/utils/utils'
import { v4 as uuidv4 } from 'uuid'

import Loading from '../Components/Loading/Loading'
import washingCupImg from './washing-cup.png'

const codeReader = new BrowserMultiFormatReader()

const GET_RECORDS = gql`
  query cup_washed_record_aggregate(
    $_gte1: timestamp = ""
    $_lte1: timestamp = ""
    $_gte2: timestamp = ""
    $_lte2: timestamp = ""
    $_gte3: timestamp = ""
    $_lte3: timestamp = ""
  ) {
    d1: cup_washed_record_aggregate(
      where: {
        create_time: { _gte: $_gte1 }
        _and: { create_time: { _lte: $_lte1 } }
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
    d2: cup_washed_record_aggregate(
      where: {
        create_time: { _gte: $_gte2 }
        _and: { create_time: { _lte: $_lte2 } }
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
    d3: cup_washed_record_aggregate(
      where: {
        create_time: { _gte: $_gte3 }
        _and: { create_time: { _lte: $_lte3 } }
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
`
const useRecentCupWashedRecord = () =>
  useQuery(GET_RECORDS, {
    variables: {
      _gte1: startOfToday(),
      _lte1: endOfToday(),
      _gte2: subDays(startOfToday(), 1),
      _lte2: subDays(endOfToday(), 1),
      _gte3: subDays(startOfToday(), 2),
      _lte3: subDays(endOfToday(), 2),
    },
  })

export const CupWashedRecordList = (props) => {
  const recordQueryResult = useRecentCupWashedRecord()

  return (
    <>
      <CupWashedRecordsGroupByDay {...recordQueryResult} />
      <List exporter={xlsxExporter} {...props}>
        <Datagrid rowClick={undefined} bulkActionButtons={false}>
          {/* <TextField label="id" source="id" /> */}

          <FunctionField
            label="杯子id"
            render={(record) => (
              <Link to={`/cup/${record.cup_id}/show`}>{record.cup_id}</Link>
            )}
          />
          <DateField label="創立時間" source="create_time" />
          <TextField label="step" source="step" />
          {/* <DateField label="更新時間" source="updated_time" /> */}
          <TextField label="其他資訊" source="note" />
        </Datagrid>
      </List>
    </>
  )
}

const ScannerInput = () => {
  const form = useFormContext()

  const [scanning, setScanning] = useState(true)
  const [alwaysOn, setAlwaysOn] = useState(true)

  useEffect(() => {
    const handleDetected = (result) => {
      // codeReader.reset()
      if (!alwaysOn) {
        setScanning(false)
      }

      vibrate()

      form.setValue('cup_id', result)
    }

    if (!scanning) {
      codeReader.reset()
      return
    }
    const hdConstraints = {
      video: {
        width: { min: 640, ideal: 1920, max: 1920 },
        height: { min: 480, ideal: 1080, max: 1080 },
        frameRate: { min: 20, ideal: 24, max: 30 },
        facingMode: 'environment',
      },
    }

    codeReader.decodeFromConstraints(
      hdConstraints,
      'video',
      (result, error) => {
        if (result) {
          handleDetected(result.getText())
        }
        if (error && !(error instanceof NotFoundException)) {
          alert(error)
        }
      }
    )

    return () => {
      codeReader.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanning, alwaysOn])

  return (
    <div style={{ width: '100%', marginBottom: 16 }}>
      <div
        style={{
          width: '100%',
          height: 300,
          background: '#111111',
          marginBottom: '8px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <video id="video" style={{ width: '100%', height: 300 }}></video>
      </div>

      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={scanning}
              onChange={(event) => {
                setScanning(event.target.checked)
              }}
            />
          }
          label="鏡頭"
        />
        <FormControlLabel
          control={
            <Switch
              checked={alwaysOn}
              onChange={(event) => {
                setAlwaysOn(event.target.checked)
              }}
            />
          }
          label="Always On"
        />
      </FormGroup>
    </div>
  )
}

const MyToolbar = () => {
  const { reset } = useFormContext()
  const recordQueryResult = useRecentCupWashedRecord()
  return (
    <Toolbar>
      <SaveButton
        fullWidth
        alwaysEnable
        variant="outlined"
        type="button"
        label="新增紀錄"
        transform={(data) => ({
          ...data,
          id: uuidv4(),
          create_time: new Date().toISOString(),
        })}
        mutationOptions={{
          onSuccess: () => {
            recordQueryResult.refetch()
            reset()
          },
        }}
      />
    </Toolbar>
  )
}

export const CupWashedRecordCreate = (props) => {
  const recordQueryResult = useRecentCupWashedRecord()
  return (
    <Create
      title={'清洗紀錄'}
      actions={
        <TopToolbar>
          <div style={{ flex: 1, display: 'flex' }}>
            <img
              alt="washingCupImg"
              src={washingCupImg}
              width={48}
              height={48}
              style={{ marginLeft: 16 }}
            ></img>
          </div>
          <ListButton {...props} />
        </TopToolbar>
      }
      {...props}
    >
      <SimpleForm toolbar={<MyToolbar />}>
        <ScannerInput />

        <TextInput
          variant="outlined"
          label="杯子 id"
          source="cup_id"
          validate={required()}
        />

        <CupWashedRecordsGroupByDay {...recordQueryResult} />

        <TextInput label="備註" source="info" />

        <br />
        <br />
      </SimpleForm>
    </Create>
  )
}

const CupWashedRecordsGroupByDay = ({ loading, error, data }: any) => {
  if (loading) {
    return <Loading />
  }
  if (error) {
    return <>{JSON.stringify(error)}</>
  }
  return (
    <>
      <MuiList>
        <ListItem>
          <ListItemText
            primary={format(startOfToday(), 'yyyy/MM/dd')}
            secondary={`本日有${data?.d1?.aggregate?.count}杯uCup進行清洗`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={format(subDays(startOfToday(), 1), 'yyyy/MM/dd')}
            secondary={`本日有${data?.d2?.aggregate?.count}杯uCup進行清洗`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={format(subDays(startOfToday(), 2), 'yyyy/MM/dd')}
            secondary={`本日有${data?.d3?.aggregate?.count}杯uCup進行清洗`}
          />
        </ListItem>
      </MuiList>
    </>
  )
}
