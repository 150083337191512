import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import { useState } from 'react'
import { lastNthDay } from 'src/utils/utils'

export interface TimeRange {
  start: Date
  end: Date
}
export enum RangeMode {
  ThisMonth = 'ThisMonth',
  LastMonth = 'LastMonth',
  Days7 = 'Days7',
  Days14 = 'Days14',
  Custom = 'Custom',
}

const createThisMonth = () => ({
  start: startOfMonth(new Date()),
  end: endOfMonth(new Date()),
})
const createLastMonth = () => ({
  start: startOfMonth(subMonths(new Date(), 1)),
  end: endOfMonth(subMonths(new Date(), 1)),
})
const create7Days = () => ({
  start: lastNthDay(7 - 1),
  end: new Date(),
})
const create14Days = () => ({
  start: lastNthDay(14 - 1),
  end: new Date(),
})

const modeFunctionMap = {
  [RangeMode.ThisMonth]: createThisMonth,
  [RangeMode.Days7]: create7Days,
  [RangeMode.Days14]: create14Days,
  [RangeMode.LastMonth]: createLastMonth,
}

export interface UseTimeRangeResult {
  range: TimeRange
  rangeMode: RangeMode
  handleRangeChange: (range: TimeRange) => void
  handleRangeModeChange: (newRangeMode: RangeMode) => void
}

const useTimeRange: () => UseTimeRangeResult = () => {
  const [rangeMode, setRangeMode] = useState<RangeMode>(RangeMode.ThisMonth)

  const [range, setRange] = useState<TimeRange>(createThisMonth())

  const handleRangeChange = (range: TimeRange) => {
    setRange(range)
  }
  const handleRangeModeChange = (newRangeMode: RangeMode) => {
    setRangeMode(newRangeMode)

    if (newRangeMode !== RangeMode.Custom) {
      const createRange = modeFunctionMap[newRangeMode]
      setRange(createRange())
    }
  }

  return {
    range,
    rangeMode,
    handleRangeChange,
    handleRangeModeChange,
  }
}
export default useTimeRange
