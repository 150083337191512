import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import useLiff from '../hooks/useLiff'
import AdminPage from '../pages/AdminPage'

const useStyles = makeStyles({
  debugFooter: {
    width: '100vw',
    overflowWrap: 'break-word',
  },
})

export default function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/liff" element={<LiffEntry />} /> */}
        <Route path="*" element={<AdminPage />} />
      </Routes>
      {/* <DebugFooter/> */}
    </Router>
  )
}

function LiffEntry() {
  // eslint-disable-next-line
  const liff = useLiff()

  return (
    <img
      alt="line logo"
      src="https://lh3.googleusercontent.com/gxFHTLjBQ7N5WSW4C-u_SRM4k8mGe68xA5pUdLC2PVC5T5h8qwQm8D6VRABKwhz-0Yg"
    />
  )
}

// eslint-disable-next-line
function DebugFooter() {
  const classes = useStyles()

  if (process.env.NODE_ENV === 'development') {
    return (
      <div className={classes.debugFooter}>
        <small>
          You are running this application in <b>development</b> mode.
        </small>
        <ul>
          <li>
            process.env.REACT_APP_MY_LIFF_ID=${process.env.REACT_APP_MY_LIFF_ID}
          </li>
          <li>
            REACT_APP_DATA_PROVIDER_URL=$
            {process.env.REACT_APP_DATA_PROVIDER_URL}
          </li>
        </ul>
      </div>
    )
  } else {
    return <></>
  }
}
