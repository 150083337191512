import { FC } from 'react'
import {
  ArrayField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  FunctionField,
  List,
  ListButton,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import ImgurImageInput from 'src/Components/RemoteImageInput'
import SmallImageField from 'src/Components/SmallImageField'
import { xlsxExporter } from 'src/utils/utils'

export const CupTypeList = (props) => (
  <List exporter={xlsxExporter} {...props}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <SmallImageField
        sx={{
          objectFit: 'contain!important',
        }}
        source="image_url"
      />
      <TextField label="name" source="name" />
      {/* <TextField
        label="有杯子id的數量"
        sortable={false}
        source="cups_aggregate.aggregate.count"
      /> */}
      <TextField
        label="在所有店家乾淨杯"
        sortable={false}
        source="cup_nums_aggregate.aggregate.sum.clean_number"
      />
      <TextField
        label="在所有店家待回收杯"
        sortable={false}
        source="cup_nums_aggregate.aggregate.sum.dirty_number"
      />
      <FunctionField
        label="在所有店家總數"
        render={(record) => {
          return (
            (record?.cup_nums_aggregate?.aggregate?.sum?.clean_number ?? 0) +
            (record?.cup_nums_aggregate?.aggregate?.sum?.dirty_number ?? 0)
          )
        }}
      />
      {/* <TextField label="總清洗次數" source="total_washed_times" /> */}
      {/* <DateField label="最近一次清洗時間" source="last_washed_time" /> */}
      {/* <DateField label="創立時間" source="create_time" /> */}
      {/* <DateField label="更新時間" source="updated_time" /> */}
      <TextField label="其他資訊" source="info" />
    </Datagrid>
  </List>
)

export const CupTypeEdit: FC<EditProps> = (props) => (
  <Edit
    redirect={false}
    actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }
    {...props}
  >
    <SimpleForm>
      {/* <Labeled label="id">
        <TextField source="id" />
      </Labeled> */}

      <TextInput label="name" source="name" />

      <ImgurImageInput source="image_url" />

      <ArrayField label="杯子" source="cups">
        <Datagrid bulkActionButtons={false}>
          <TextField label="id" source="id" />
          <DateField label="時間" source="create_time" showTime />
          <TextField label="總清洗次數" source="total_washed_times" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
)

export const CupTypeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="name" source="name" />
      <ImgurImageInput source="image_url" />

      <TextInput label="其他資訊" source="info" />

      <DateTimeInput
        disabled
        label="創立時間"
        source="create_time"
        defaultValue={new Date().toISOString()}
      />
    </SimpleForm>
  </Create>
)
