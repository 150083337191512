import { getProfile } from './auth/utils'

const apiUrl = `${process.env.REACT_APP_DATA_PROVIDER_URL}`

export enum Role {
  STORE = 'store',
  CLERK = 'clerk',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

const commonHeaders = {
  'Content-Type': 'application/json',
}

const invalidJwt = (error: any) => {
  // if (Array.isArray(error?.graphQLErrors)) {
  //   error.graphQLErrors.forEach((err) => {
  //     if (err instanceof GraphQLError) {
  //       console.log(err)
  //       err.me
  //     }
  //   })
  // }
  return error?.message === 'Could not verify JWT: JWTExpired'
}

const authProvider = {
  // called when the user attempts to log in
  login: async ({ username, password, model }) => {
    const action = 'login'

    const response = await fetch(`${apiUrl}/users/${action}`, {
      method: 'POST',
      headers: {
        ...commonHeaders,
      },
      // Only oauth login use FormData
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
    const resJson = await response.json()
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText + ': ' + resJson.result)
    }

    const { token } = resJson
    localStorage.setItem('token', token)

    return Promise.resolve()
  },

  // called when the user clicks on the logout button
  logout: () => {
    console.log('logout')
    // console.trace()
    // alert('removeToken')
    // alert(getStackTrace());
    // localStorage.setItem('log', getStackTrace())

    localStorage.removeItem('token')
    // go to /login
    window.location.href = '/login'
    return Promise.resolve()
  },
  // called when the API returns an error
  /**
   * Fortunately, each time the dataProvider returns an error,
   * react-admin calls the authProvider.checkError() method.
   * If it returns a rejected promise,
   * react-admin calls the authProvider.logout() method immediately,
   * and asks the user to log in again.
   */
  checkError: (error) => {
    console.log('checkError')
    // alert('checkError')
    console.log({ error })
    // alert(`checking error ${error.toString()}`)
    if (error?.status === 401 || error?.status === 403 || invalidJwt(error)) {
      // alert('removeToken in checkError')
      console.log('checkError > removeItem')
      localStorage.removeItem('token')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    console.log('checkAuth')
    const token = localStorage.getItem('token')
    if (token === null) {
      console.log('checkAuth: no token')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    console.log('getPermissions')

    try {
      const { role } = getProfile()
      if (role === 'store') {
        // alert('permission: business')
        return Promise.resolve('store')
      } else if (role === 'admin') {
        return Promise.resolve('admin')
      } else if (role === Role.MANAGER) {
        return Promise.resolve('manager')
      } else {
        console.log('getPermissions error: No role in user')
        localStorage.removeItem('token')
        return Promise.resolve('store')
      }
    } catch (err) {
      console.log('getPermissions error')
      return Promise.reject()
    }
  },
  getIdentity: () => {
    try {
      const profile = getProfile()

      return Promise.resolve({
        id: profile?.id,
        fullName: profile?.store_name ?? profile?.username,
        avatar: profile?.image_url,
      })
    } catch (error) {
      return Promise.reject(error)
    }
  },
}

export default authProvider
