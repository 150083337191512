import { SxProps } from '@mui/material'
import { ImageField, ImageFieldProps } from 'react-admin'

function SmallImageField({
  size = 'small',
  sx = {},
  fieldSx = {},
  ...props
}: {
  size?: 'small' | 'medium' | 'big'
  fieldSx?: SxProps
} & ImageFieldProps) {
  const sizePx = size === 'small' ? 40 : size === 'medium' ? 80 : 120

  return (
    <ImageField
      sortable={false}
      sx={
        {
          width: sizePx,
          height: sizePx,
          '& .RaImageField-image': {
            width: sizePx,
            height: sizePx,
            margin: 0,
            objectFit: 'contain',
            ...sx,
          },
          ...fieldSx,
        } as any
      }
      {...props}
    />
  )
}

export default SmallImageField
