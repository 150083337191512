import { toDateString } from 'src/utils/utils'

export function truncateHasuraDate(
  data: any[] | undefined,
  timeFeild = 'create_time'
) {
  if (data === undefined) {
    return []
  }
  return data.map((record) => ({
    ...record,
    [timeFeild]: toDateString(record[timeFeild]),
  }))
}

export function truncateHighWallDate(
  data: any[] | undefined,
  timeFeild = 'create_time'
) {
  if (data === undefined) {
    return []
  }
  return data.map((record) => ({
    ...record,
    [timeFeild]: record[timeFeild][0],
  }))
}
