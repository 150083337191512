import './loading-logo.css'

// https://codepen.io/mschenhe/pen/vYmLEpZ
const LoadingLogo = () => {
  return (
    <div className="loading">
      <div className="boxLoading"></div>
    </div>
  )
}

const FullScreenLoading = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingLogo />
    </div>
  )
}

export default FullScreenLoading
