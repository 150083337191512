import {
  Edit,
  ImageField,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin'

const ProfileEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

export const ProfileEdit = ({ ...props }) => {
  return (
    <Edit
      /*
                As this component isn't loaded from a route generated by a <Resource>,
                I have to provide the id myself.
                As there is only one config for the current user, I decided to
                hard-code it here
            */
      id="profile"
      /*
                For the same reason, I need to provide the resource and basePath props
                which are required by the Edit component
            */
      resource="profile"
      basePath="/profile"
      redirect={false}
      title="我的帳號"
      {...props}
    >
      <SimpleForm toolbar={<ProfileEditToolbar />}>
        <p>名稱、圖片、詳細資訊等將呈現於使用者，作為宣傳用，可自由填寫！</p>
        <ImageField source="image_url" label="圖片" />
        <TextInput source="image_url" label="圖片網址" />
        <TextInput label="名稱" source="store_name" validate={required()} />
        {/* <TextInput label="" source="phone" disabled /> */}
        {/* <TextInput label="" source="email" /> */}
        <TextInput label="詳細資訊" source="info" />
        <TextInput label="地址" source="address" />
        <TextInput label="緯度" source="latitude" />
        <TextInput label="經度" source="longitude" />
        <TextInput label="網站連結" source="website" />
      </SimpleForm>
    </Edit>
  )
}
