import { green, lightBlue, yellow } from '@mui/material/colors'
// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { createTheme, PaletteColorOptions } from '@mui/material/styles'
import { defaultTheme } from 'react-admin'

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends Theme {}
// }
export const uCupRed = '#FF8484'
export const uCupBlue = '#66CFEC'

const uCupPrimary: PaletteColorOptions = {
  ...lightBlue,
  light: '#D4F3FF',
  main: uCupBlue,
  dark: '#3A669B',
}
const uCupSecondary: PaletteColorOptions = {
  ...yellow,
  main: '#FFE471',
}
const uCupSuccess: PaletteColorOptions = {
  ...green,
  main: '#88E283',
}
const uCupWarning: PaletteColorOptions = {
  ...yellow,
  main: '#FFAD61',
}
const uCupError: PaletteColorOptions = {
  ...yellow,
  main: uCupRed,
}

const muiTheme = createTheme({
  shape: {
    borderRadius: 10,
  },
  palette: {
    background: {
      default: '#F3F9FC',
    },
    primary: uCupPrimary,
    secondary: uCupSecondary,
    success: uCupSuccess,
    warning: uCupWarning,
    error: uCupError,
    grey: {
      A100: '#C4C4C4',
      '600': '#777777',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans TC',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
  },
})

const theme = {
  ...defaultTheme,
  ...muiTheme,
  components: {
    ...defaultTheme.components,
    ...muiTheme.components,

    RaListToolbar: {
      styleOverrides: {
        root: {
          marginBottom: '8px',
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          color: 'black',

          // invisible border when not active, to avoid position flashs
          borderLeft: '3px solid transparent',
          '&.RaMenuItemLink-active': {
            color: 'black',
            borderLeft: '10px solid ' + uCupSecondary.main,
          },
          '& .RaMenuItemLink-icon': {
            color: 'black',
          },
        },
      },
    },
  },
}

export default theme
