import { addYears } from 'date-fns'
import { FC } from 'react'
import {
  ArrayField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  Labeled,
  List,
  ListButton,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import { xlsxExporter } from 'src/utils/utils'

export const CupList = (props) => (
  <List
    exporter={xlsxExporter}
    filters={[
      <ReferenceInput
        alwaysOn
        key="cup_type_id"
        source="cup_type_id"
        reference="cup_type"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>,
    ]}
    {...props}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField label="id" source="id" />
      {/* <TextField label="總清洗次數" source="total_washed_times" /> */}
      {/* <DateField label="最近一次清洗時間" source="last_washed_time" /> */}
      <DateField label="預計淘汰時間" source="expired_time" />
      <DateField label="創立時間" source="create_time" />
      {/* <DateField label="更新時間" source="updated_time" /> */}
      <TextField label="其他資訊" source="info" />
    </Datagrid>
  </List>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CupTitle = ({ record = {} }: any) => {
  return <span>Cup {`${record?.id ?? ''}`}</span>
}

export const CupEdit: FC<EditProps> = (props) => (
  <Edit
    redirect={false}
    title={<CupTitle />}
    actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }
    {...props}
  >
    <SimpleForm>
      <Labeled label="id">
        <TextField source="id" />
      </Labeled>

      <br />
      <ReferenceInput sx={{ mt: 1 }} source="cup_type_id" reference="cup_type">
        <SelectInput optionText="name" />
      </ReferenceInput>

      {/* <NumberInput label="總清洗次數" source="total_washed_times" /> */}

      {/* <Labeled label="最近一次清洗時間">
        <DateField showTime source="last_washed_time" />
      </Labeled> */}

      <Labeled label="預計淘汰時間">
        <DateField showTime source="expired_time" />
      </Labeled>

      <Labeled label="創立時間">
        <DateField showTime source="create_time" />
      </Labeled>
      {/* <DateField showTime label="更新時間" source="updated_time" /> */}

      <Labeled label="其他資訊">
        <TextInput label="" source="info" />
      </Labeled>

      <Labeled label="總清洗次數">
        <TextField
          sortable={false}
          source="cup_washed_records_aggregate.aggregate.count"
        />
      </Labeled>
      <Labeled label="清洗紀錄">
        <ArrayField source="cup_washed_records">
          <Datagrid bulkActionButtons={false}>
            <DateField label="時間" source="create_time" showTime />
            <TextField label="備註" source="note" />
          </Datagrid>
        </ArrayField>
      </Labeled>

      <Labeled label="借用紀錄">
        <ArrayField source="rents">
          <Datagrid bulkActionButtons={false}>
            <TextField label="id" source="id" />
            <DateField label="時間" source="create_time" showTime />
            <TextField label="店家" source="store.store_name" />
          </Datagrid>
        </ArrayField>
      </Labeled>

      <Labeled label="歸還紀錄">
        <ArrayField source="returns">
          <Datagrid bulkActionButtons={false}>
            <TextField label="id" source="id" />
            <DateField label="時間" source="create_time" showTime />
            <TextField label="店家" source="store.store_name" />
          </Datagrid>
        </ArrayField>
      </Labeled>
    </SimpleForm>
  </Edit>
)

export const CupCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="id" source="id" />
      <NumberInput
        label="總清洗次數"
        source="total_washed_times"
        defaultValue={0}
      />
      <DateTimeInput
        label="最近一次清洗時間"
        source="last_washed_time"
        defaultValue={new Date().toISOString()}
      />
      <DateTimeInput
        label="預計淘汰時間"
        source="expired_time"
        defaultValue={addYears(new Date(), 1).toISOString()}
      />
      <DateTimeInput
        label="創立時間"
        source="create_time"
        defaultValue={new Date().toISOString()}
      />
      <DateTimeInput
        label="更新時間"
        source="updated_time"
        defaultValue={new Date().toISOString()}
      />

      <TextInput label="其他資訊" source="info" />
    </SimpleForm>
  </Create>
)
