// components
import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  TextField,
  useList,
} from 'react-admin'

import SmallImageField from './SmallImageField'

// my components

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },
  col6: {
    flex: '0 0 50%',
  },
}))

function CupCard({
  cupNums = [],
  loading,
  showDirtyNumber = false,
  showAll = false,
}: {
  showDirtyNumber?: boolean
  showAll?: boolean
  cupNums?: {
    id: string
    clean_number?: number
    dirty_number?: number
    cup_type?: {
      id?: string
      name?: string
      image_url?: string
    }
  }[]
  loading?: boolean
}) {
  const listContext = useList({ data: cupNums, isLoading: loading })

  const cleanNumberSum = cupNums?.reduce(
    (acc, cur) => acc + (cur?.clean_number ?? 0),
    0
  )
  const dirtyNumberSum = cupNums?.reduce(
    (acc, cur) => acc + (cur?.dirty_number ?? 0),
    0
  )
  const totalSum = cleanNumberSum + dirtyNumberSum

  // 如果空白杯款杯數是0就把它隱藏
  const cupNumsToDisplay = cupNums?.filter(
    (cupNum) =>
      !(typeof cupNum?.cup_type?.id !== 'string' && cupNum?.clean_number === 0)
  )

  return (
    <>
      <Paper>
        <ListContextProvider value={listContext}>
          <Datagrid data={cupNumsToDisplay ?? []} bulkActionButtons={false}>
            <SmallImageField
              size="medium"
              label="杯款"
              source="cup_type.image_url"
            />
            <FunctionField
              label=""
              source="cup_type.name"
              render={(record) =>
                typeof record?.cup_type?.name === 'string' ? (
                  <>{record?.cup_type?.name}</>
                ) : (
                  <>不分杯款</>
                )
              }
            />
            <TextField label="乾淨" source="clean_number" />
            {showDirtyNumber && (
              <TextField label="待回收" source="dirty_number" />
            )}
            {showAll && (
              <FunctionField
                label="總數"
                render={(record) =>
                  (record?.clean_number ?? 0) + (record?.dirty_number ?? 0)
                }
              />
            )}
          </Datagrid>
        </ListContextProvider>
        {/* dirtyNumberSum */}
      </Paper>
      <div
        style={{
          marginTop: 16,
          gap: 16,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Typography variant="body2">{cleanNumberSum} 乾淨</Typography>
        <Typography variant="body2">{dirtyNumberSum} 待回收</Typography>
        <Typography variant="body2">{totalSum} 總數</Typography>
      </div>
    </>
  )
}

export default CupCard
