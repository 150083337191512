import React, { FC } from 'react'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  List,
  ReferenceInput,
  SimpleForm,
  TextField,
} from 'react-admin'
import { xlsxExporter } from 'src/utils/utils'

export const StoreManagementList = ({ permissions = undefined, ...props }) => {
  return (
    <List
      exporter={xlsxExporter}
      bulkActionButtons={false}
      {...props}
      sort={{ field: 'staff_id', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="staff.username" />
        <TextField source="store.store_name" />
        <DateField source="create_time" showTime />
      </Datagrid>
    </List>
  )
}

export const StoreManagementEdit: FC<EditProps> = (props) => (
  <Edit redirect={false} {...props}>
    <SimpleForm>
      <ReferenceInput source="staff_id" reference="staff">
        <AutocompleteInput label="人員" />
      </ReferenceInput>
      <ReferenceInput source="store_id" reference="store">
        <AutocompleteInput label="店家" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const StoreManagementCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="staff_id" reference="staff">
          <AutocompleteInput label="人員" />
        </ReferenceInput>
        <ReferenceInput source="store_id" reference="store">
          <AutocompleteInput label="店家" />
        </ReferenceInput>
        <DateTimeInput
          label="創立時間"
          source="create_time"
          defaultValue={new Date().toISOString()}
        />
      </SimpleForm>
    </Create>
  )
}
