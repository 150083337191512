import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { RangeMode, UseTimeRangeResult } from '../hooks/useTimeRange'

/*
 *   range = {
 *       start: new Date(),
 *       end: new Date()
 *   }
 *
 */
function CustomDateRangePicker({ range, onChange }) {
  const handleStartChange = (date) => {
    onChange({ ...range, start: date })
  }
  const handleEndChange = (date) => {
    onChange({ ...range, end: date })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container wrap="nowrap">
        <DatePicker
          label="起始日期"
          inputFormat="yyyy/MM/dd"
          value={range.start}
          onChange={handleStartChange}
          renderInput={(params) => (
            <TextField {...params} sx={{ mx: 2 }} margin="normal" />
          )}
        />
        <DatePicker
          label="結束日期"
          inputFormat="yyyy/MM/dd"
          value={range.end}
          onChange={handleEndChange}
          renderInput={(params) => (
            <TextField {...params} sx={{ mx: 2 }} margin="normal" />
          )}
        />
      </Grid>
    </LocalizationProvider>
  )
}

interface DateRangePickerProps {
  range: UseTimeRangeResult['range']
  rangeMode: UseTimeRangeResult['rangeMode']
  onRangeChange: UseTimeRangeResult['handleRangeChange']
  onRangeModeChange: UseTimeRangeResult['handleRangeModeChange']
}
function DateRangePicker({
  range,
  rangeMode,
  onRangeChange,
  onRangeModeChange,
}: DateRangePickerProps) {
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <InputLabel>範圍</InputLabel>
        <Select
          value={rangeMode}
          onChange={(event) => {
            onRangeModeChange(event.target.value as RangeMode)
          }}
        >
          <MenuItem value={RangeMode.ThisMonth}>這個月</MenuItem>
          <MenuItem value={RangeMode.Days7}>前7天</MenuItem>
          <MenuItem value={RangeMode.Days14}>前14天</MenuItem>
          <MenuItem value={RangeMode.LastMonth}>上個月</MenuItem>
          <MenuItem value={RangeMode.Custom}>自訂</MenuItem>
        </Select>
      </div>

      {rangeMode === RangeMode.Custom && (
        <CustomDateRangePicker range={range} onChange={onRangeChange} />
      )}
    </div>
  )
}

export default DateRangePicker
