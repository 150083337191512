import jsonExport from 'jsonexport/dist'
import get from 'lodash/get'
import { RaRecord } from 'react-admin'
import { read, writeFile } from 'xlsx'

export const vibrate = () => {
  // vibrate for 200ms
  if (window.navigator && window.navigator.vibrate) {
    window.navigator.vibrate(200)
  }
}

export const determineProvider = (userId) => {
  if (userId === undefined || userId === null) {
    return 'invalid'
  }
  const studentCardRegexp = /^[a-zA-Z]{1}[a-zA-Z0-9]{8,9}$/
  const lineRegexp = /^[a-zA-Z0-9]{28,33}$/i
  // ex: 8dfda012-0c50-417a-aa14-f91534392b85
  const uuidRegexp =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
  const nfcRegexp = /^[A-F0-9]{8}$/
  if (userId.match(lineRegexp)) {
    return 'Line'
  } else if (userId.match(uuidRegexp)) {
    return 'Normal'
  } else if (userId.match(studentCardRegexp)) {
    return 'NTU Mail'
  } else if (userId.match(nfcRegexp)) {
    return 'NFC'
  } else {
    return 'invalid'
  }
}

export const toChinese = {
  Self: '自備',
  uCup: '借用uCup',
}

/**
 *
 * @param {*} data
 * @param {*} key
 * @return json = {
 *  {key1: value},
 *  {key2: value},
 * }
 */
export function toCountJson(data, key) {
  const newData = {}
  Object.values(data).map((record) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newKey = (record as any)[key]

    if (newData[newKey] === undefined) {
      newData[newKey] = 1
    } else {
      newData[newKey] += 1
    }
    return 0
  })

  return newData
}

export interface XYRecord {
  x: string | number | Date
  y: number
}

/**
 *
 * @param {*} data
 * example: data = {
 *  '2022-01-01': 10,
 *  '2022-01-02': 5,
 * }
 * @return Array<{
    x:    string | number | Date,
    y: number
}>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toXYData(data: Record<string, any>): XYRecord[] {
  const parsedData = Object.keys(data).map((key) => {
    return { x: key, y: data[key] }
  })

  return parsedData
}

export function chooseXY(data: RaRecord[], x: string, y: string): XYRecord[] {
  const parsedData = Object.keys(data).map((id) => {
    return { x: data[id][x], y: data[id][y] }
  })

  return parsedData
}

export function sumYbyX(xyData: XYRecord[]): XYRecord[] {
  const count: Record<string, number> = {}

  xyData.forEach((xyRecord) => {
    if (count[xyRecord.x.toString()]) {
      count[xyRecord.x.toString()] += xyRecord.y
    } else {
      count[xyRecord.x.toString()] = xyRecord.y
    }
  })

  return Object.keys(count).map((x) => ({ x, y: count[x] }))
}

/**
 * 
 * @param {*} data 
 * @return Array<{
    id:    string | number,
    value: number
}>
 */
export function toIdValueData(data) {
  const parsedData = Object.keys(data).map((key) => {
    return {
      id: key,
      label: key,
      value: data[key],
    }
  })

  return parsedData
}

export function toDateString(timestampString: string) {
  return timestampString.split('T')[0]
}
export function lastNthDay(shift) {
  const startDateTmp = new Date()
  startDateTmp.setDate(new Date().getDate() - shift)
  return startDateTmp
}

export const xlsxExporter = (records, _a, _b, resource) => {
  jsonExport(records, {}, (err, csv) => {
    if (err) {
      throw Error(err)
    }
    const wb = read(csv, { type: 'string' })
    writeFile(wb, resource + '.xlsx')
  })
}

export const datagridExporter = (columns: JSX.Element[]) => {
  console.log({ columns })
  return (records, _a, _b, resource) => {
    const mappedRecords = records.map((record) => {
      const mappedRecord = {}
      columns.forEach((column) => {
        const title = column.props?.label ?? column.props?.source
        const value = get(record, column.props.source)
        const renderedValue =
          typeof column.props.render === 'function'
            ? column.props.render(record)
            : undefined
        if (
          column.props?.source === undefined &&
          column.props?.render === undefined
        )
          return

        mappedRecord[title] = renderedValue ?? value ?? ''
      })
      return mappedRecord
    })
    console.log(mappedRecords)
    xlsxExporter(mappedRecords, _a, _b, resource)
  }
}

export function shortRandomId() {
  return Math.random().toString(36).substring(2, 7)
}
