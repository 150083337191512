import { FC } from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditProps,
  ImageField,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'
import { xlsxExporter } from 'src/utils/utils'

import SmallImageField from '../Components/SmallImageField'

export const ProductList = (props) => (
  <List
    exporter={xlsxExporter}
    filters={[
      <TextInput key="id" label="id" source="id@_eq" alwaysOn />,
      <TextInput
        key="store"
        label="商店"
        source="store#store_name@_ilike"
        alwaysOn
      />,
      <TextInput
        key="product_name"
        label="產品"
        source="product_name@_ilike"
        alwaysOn
      />,
      <BooleanInput
        sx={{ marginBottom: 1 }}
        key="active"
        label="未封存"
        source="active"
        defaultChecked={true}
        alwaysOn
      />,
    ]}
    filterDefaultValues={{ active: true }}
    {...props}
  >
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <SmallImageField source="image_url" />
      <TextField label="店名" source="store.store_name" />
      <TextField label="產品名" source="product_name" />
      <TextField label="描述" source="product_info" />
      <DateField label="建立時間" source="create_time" />
      <DateField label="更新時間" source="updated_time" />
      <DateField label="期限" source="deadtime" />
      <BooleanField label="呈現給使用者" source="active" />
      <NumberField label="需求點數" source="point" />
      <NumberField label="價錢" source="price" />
      <NumberField
        label="庫存"
        sortable={false}
        source="inventories[0].number"
      />
      <NumberField label="編號" source="id" />
      <NumberField label="顯示順序" source="display_order" />
    </Datagrid>
  </List>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProductTitle = ({ record = {} }: any) => {
  return <span>Product {`${record?.title ?? ''}`}</span>
}

const dateFormatter = (datetime: [string, string] | string | undefined) => {
  if (typeof datetime === 'string') {
    return datetime.split('Z')[0]
  }
  if (datetime?.length !== 2) {
    return ''
  }
  return `${datetime[0]}T${datetime[1]}`
}

const dateParser = (date: string) => {
  const d = new Date(date)

  // this is what api needs
  // %Y-%m-%dT%H:%M:%SZ
  return d.toISOString().split('.')[0] + 'Z'
}

export const ProductEdit: FC<EditProps> = (props) => (
  <Edit
    redirect={false}
    title={<ProductTitle />}
    resource="products"
    {...props}
  >
    <SimpleForm>
      <ImageField source="image_url" />
      <TextInput source="image_url" />
      <TextInput source="store_id" />
      <TextInput source="product_name" />
      <TextInput source="product_info" />
      <NumberInput source="point" />
      <NumberInput source="price" />
      <NumberInput source="inventory" />
      <DateTimeInput
        source="deadtime"
        format={dateFormatter}
        parse={dateParser}
      />
      <BooleanInput source="active" />
      <NumberInput label="顯示順序" source="display_order" />
    </SimpleForm>
  </Edit>
)

export const ProductCreate = (props) => (
  <Create {...props} resource="products" redirect="product">
    <SimpleForm>
      <TextInput source="image_url" />
      <TextInput source="store_id" />
      <TextInput source="product_name" />
      <TextInput source="product_info" />
      <NumberInput source="point" />
      <NumberInput source="price" />
      <NumberInput source="inventory" />
      <DateTimeInput
        source="deadtime"
        format={dateFormatter}
        parse={dateParser}
      />
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
)
