import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { FC } from 'react'
import {
  Create,
  Datagrid,
  Edit,
  EditProps,
  List,
  ListButton,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import { useWatch } from 'react-hook-form'
import { xlsxExporter } from 'src/utils/utils'

export const CouponList = (props) => (
  <List exporter={xlsxExporter} {...props}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <TextField label="code" source="code" />
      <TextField label="name" source="name" />
      <TextField label="bonus_change" source="bonus_change" />
      {/* <DateField label="更新時間" source="updated_time" /> */}
      <TextField label="usage_count" source="usage_count" />
      <TextField label="usage_limit" source="usage_limit" />
    </Datagrid>
  </List>
)

const MutaionFields = ({ mode }: { mode: 'create' | 'edit' }) => {
  const watch = useWatch()
  const watchCode = watch['code']
  const url = `https://liff.line.me/${process.env.REACT_APP_FRONTEND_LIFF_ID}/bonuses/create?coupon_code=${watchCode}`

  return (
    <>
      <TextInput label="code" source="code" />
      <TextInput label="name" source="name" />
      <NumberInput label="bonus_change" source="bonus_change" />
      <NumberInput label="usage_count" source="usage_count" />
      <NumberInput label="usage_limit" source="usage_limit" />

      {/* a button that can copy the link to clipboard that can be sent to customer with frontend domain */}
      {mode === 'edit' && (
        <>
          <Typography variant="body1">{url}</Typography>
          <Button onClick={() => navigator.clipboard.writeText(url)}>
            Copy
          </Button>
        </>
      )}
    </>
  )
}

export const CouponEdit: FC<EditProps> = (props) => (
  <Edit
    redirect={false}
    actions={
      <TopToolbar>
        <ListButton />
      </TopToolbar>
    }
    {...props}
  >
    <SimpleForm>
      <MutaionFields mode="edit"></MutaionFields>
    </SimpleForm>
  </Edit>
)

export const CouponCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <MutaionFields mode="create"></MutaionFields>
    </SimpleForm>
  </Create>
)
