import { useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import { addDays, format, subDays } from 'date-fns'
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  FunctionField,
  ImageField,
  List,
  ListButton,
  Pagination,
  Show,
  SimpleList,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin'
import PresignedImage from 'src/Components/PresignedImage'
import { xlsxExporter } from 'src/utils/utils'

import { toChinese } from '../utils/utils'
import Business from './Business'

export const ReturnList = ({ ...props }) => {
  const { permissions } = usePermissions()

  const isSmall = useMediaQuery((theme) =>
    (theme as any).breakpoints.down('lg')
  )

  return (
    <List
      exporter={xlsxExporter}
      title="歸還紀錄"
      sort={{ field: 'create_time', order: 'DESC' }}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      filterDefaultValues={{
        'create_time@_gte': format(subDays(new Date(), 14), 'yyyy-MM-dd'),
        'create_time@_lte': format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      }}
      filters={[
        <DateInput
          key="Create Time"
          label="從"
          source="create_time@_gte"
          alwaysOn
        />,
        <DateInput
          key="Create Time"
          label="到"
          source="create_time@_lte"
          alwaysOn
        />,
        <TextInput
          key="區域"
          label="區域"
          source="store#store_managements#staff#username@_ilike,store#store_managements#staff_id@_eq"
          alwaysOn
        />,
        <TextInput
          key="store"
          label="商店"
          source="store#store_name@_ilike"
          alwaysOn
        />,
      ]}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => {
            if (permissions === 'admin') {
              return `#${record.id} ${record.store.store_name} `
            } else if (permissions === 'business') {
              return `歸還編號：${record.id}`
            }
          }}
          secondaryText={(record) => {
            return (
              <div>
                <Typography variant="body2">
                  歸還時間：
                  {`${record.create_time}`}
                </Typography>
                <Typography variant="body2">
                  歸還地點：{record.store.store_name}
                </Typography>
                <Typography variant="body2">
                  租借編號：{record.rent_id}
                </Typography>
              </div>
            )
          }}
          tertiaryText={(record) => `${toChinese[record.rent_type]}`}
          linkType={'show'}
        />
      ) : (
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="id" />
          <TextField source="store.store_name" />
          <TextField label="使用者" source="rent.user_id" />
          <TextField label="使用途徑" source="rent.provider" />
          <DateField source="create_time" showTime />
        </Datagrid>
      )}
    </List>
  )
}

const ReturnCreateActions = () => (
  <TopToolbar>
    <ListButton />
  </TopToolbar>
)

export const ReturnCreate = (props) => (
  <Create actions={<ReturnCreateActions />} {...props}>
    <Business />
  </Create>
)

const ReturnShowActions = () => (
  <TopToolbar>
    <ListButton />
  </TopToolbar>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ReturnShow = ({ ...props }) => {
  const { permissions } = usePermissions()
  return (
    <Show actions={<ReturnShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField label="歸還店家" source="store_name" />
        <DateField label="歸還時間" source="create_time" showTime />
        <ImageField source="store.image_url" />
        {permissions === 'admin' && (
          <FunctionField
            label="歸還照片"
            render={(record) => (
              <PresignedImage
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain',
                }}
                filename={`returns/${record.id}/image.jpg`}
              />
            )}
          />
        )}
      </SimpleShowLayout>
    </Show>
  )
}
