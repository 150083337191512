import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useEffect } from 'react'
import {
  Create,
  Datagrid,
  DateField,
  List,
  TextField,
  useCreate,
  useNotify,
} from 'react-admin'
import { useSearchParams } from 'react-router-dom'
import { xlsxExporter } from 'src/utils/utils'

const REDIRECT_URI = window.location.origin + '/store_notify/create'

export const LineNotifyButton = (props) => {
  return (
    <Button
      variant="outlined"
      color="inherit"
      {...props}
      sx={{ color: 'white', background: 'green', ...props.sx }}
    >
      綁定 LINE Notify
    </Button>
  )
}

export const GoLineNotifyButton = () => {
  return (
    <LineNotifyButton
      onClick={() => {
        window.location.href =
          'https://notify-bot.line.me/oauth/authorize?response_type=code&scope=notify&response_mode=xxform_post' +
          '&client_id=bR9LWTDtn1gVVny3nMEuAh' +
          `&redirect_uri=${REDIRECT_URI}` +
          '&state=rtyuikjhgfr'
      }}
    />
  )
}

export const StoreNotifyCreate = (props) => {
  const [searchParams, _] = useSearchParams()

  const [create, { isLoading, isSuccess }] = useCreate()
  const notify = useNotify()

  useEffect(() => {
    const code = searchParams.get('code')
    if (code) {
      create(
        'store_notify/create',
        {
          data: {
            code,
            redirect_uri: REDIRECT_URI,
          },
        },
        {
          onSuccess: () => {
            notify('綁定成功！')
          },
          onError: (error) =>
            notify(`綁定錯誤： ${JSON.stringify(error)} `, {
              type: 'warning',
            }),
        }
      )
    }
  }, [searchParams])

  return (
    <Create {...props}>
      <div
        style={{
          height: '70vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!isLoading && !isSuccess && <GoLineNotifyButton />}
        {isLoading && '綁定 LINE 通知中...'}
        {isSuccess && '綁定成功！'}

        <br />
        <br />
        <LineNotifyInfo />
      </div>
    </Create>
  )
}

export const LineNotifyInfo = () => {
  return (
    <>
      <Link
        target="_blank"
        href="https://help2.line.me/line_notify/web/?lang=zh_TW&contentId=20003053"
      >
        什麼是 LINE Notify？
      </Link>
      <Link
        target="_blank"
        href="https://help2.line.me/line_notify/web/?lang=zh_TW&contentId=20003056"
      >
        如何解除綁定
      </Link>
      <Link target="_blank" href="https://notify-bot.line.me/my/" color="error">
        解除綁定
      </Link>
    </>
  )
}
export const StoreNotifyList = (props) => (
  <List exporter={xlsxExporter} {...props}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '16px',
        gap: 8,
      }}
    >
      <p>已綁定列表</p>

      <LineNotifyInfo />
    </div>
    <Datagrid bulkActionButtons={false}>
      <TextField source="store.store_name" />
      <TextField source="staff.username" />
      <TextField source="access_token" />
      <DateField source="create_time" showTime />
    </Datagrid>
  </List>
)
