import Grid from '@mui/material/Grid'
import { addDays, format } from 'date-fns'
import { useGetIdentity, useGetList, useGetOne } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import CupCard from 'src/Components/CupCard'
import { LineNotifyButton } from 'src/pages/store-notifies'
import { chooseXY } from 'src/utils/utils'

import RentRecordChart from '../charts/RentRecordChart'
import DateRangePicker from '../components/DateRangePicker'
import useTimeRange from '../hooks/useTimeRange'
import { truncateHighWallDate } from '../utils'

function BusinessDashboard() {
  const navigate = useNavigate()

  const { data } = useGetIdentity()
  const storeQuery = useGetOne('stores', {
    id: data?.id,
  })
  const cupNums = storeQuery.data?.cup_nums

  const { range, rangeMode, handleRangeChange, handleRangeModeChange } =
    useTimeRange()

  const rentCountByDay = useGetList('record/rent_count_by_day', {
    pagination: { page: 1, perPage: 365 },
    filter: {
      date_gte: format(range.start, 'yyyy-MM-dd'),
      date_lte: format(addDays(range.end, 0), 'yyyy-MM-dd'),
    },
  })
  const rentCountXYRecord = chooseXY(
    truncateHighWallDate(rentCountByDay.data, 'datetime'),
    'datetime',
    'rent_count'
  )

  const firstUsers = useGetList('record/get_first_users_in_store', {
    pagination: { page: 1, perPage: 365 },
    filter: {
      date_gte: format(range.start, 'yyyy-MM-dd'),
      date_lte: format(addDays(range.end, 0), 'yyyy-MM-dd'),
    },
  })

  return (
    <div style={{ padding: 12 }}>
      <CupCard cupNums={cupNums} loading={storeQuery.isLoading} />
      <LineNotifyButton
        fullWidth
        sx={{ my: 2 }}
        onClick={() => {
          navigate('/store_notify/create')
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <DateRangePicker
            range={range}
            rangeMode={rangeMode}
            onRangeChange={handleRangeChange}
            onRangeModeChange={handleRangeModeChange}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <RentRecordChart
            isLoading={rentCountByDay.isLoading}
            error={rentCountByDay.error}
            data={[
              {
                id: '總數',
                data: rentCountXYRecord,
              },
            ]}
            total={rentCountXYRecord.reduce(
              (sum, xyRecord) => sum + xyRecord.y,
              0
            )}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default BusinessDashboard
