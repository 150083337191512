import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'

const myChineseTWMessages = Object.assign(englishMessages, {
  resources: {
    users: {
      name: '使用者',
      fields: {
        username: '使用者名稱',
        user_id: '使用者編號',
        access_token: '綁定帳號',
        email: '電子信箱',
        active: '顯示給消費者？',
        id: '編號',
        is_staff: '員工？',
        state: '狀態',
        create_time: '建立時間',
        update_time: '更新時間',
      },
    },
    bonuses: {
      name: '使用者點數',
      fields: {
        username: '使用者名稱',
        user_id: '使用者編號',
        email: '電子信箱',
        provider: '使用途徑',
        point: '擁有點數',
        active: '顯示給消費者？',
        id: '編號',
        is_staff: '員工？',
        state: '狀態',
        create_time: '建立時間',
        update_time: '更新時間',
      },
    },
    social_users: {
      name: '社群使用者',
      fields: {
        access_token: '綁定帳號',
        user_id: '使用者編號',
        email: '電子信箱',
        username: '使用者名稱',
        provider: '使用途徑',
        active: '顯示給消費者？',
        id: '編號',
        is_staff: '員工？',
        state: '狀態',
        create_time: '建立時間',
        update_time: '更新時間',
      },
    },
    rents: {
      name: '掃描紀錄',
      fields: {
        cup_type: '杯子類型',
        id: '編號',
        image_url: '圖片網址',
        store_name: '店名',
        point: '點數',
        state: '狀態',
        provider: '使用途徑',
        create_time: '建立時間',
        update_time: '更新時間',
      },
    },
    products: {
      name: '優惠',
      fields: {
        id: '編號',
        store_id: '店家編號',
        image_url: '圖片網址',
        store_name: '店名',
        product_name: '產品名稱',
        product_info: '產品詳細資訊',
        inventory: '庫存',
        point: '需求點數',
        deadtime: '期限',
        active: '顯示給消費者？',
        state: '狀態',
        create_time: '建立時間',
        update_time: '更新時間',
      },
    },
    stores: {
      name: '店家',
      fields: {
        store_id: '店家編號',
        image_url: '圖片網址',
        store_name: '店名',
        cup_num: '乾淨杯子數',
        active: '顯示給消費者？',
        state: '狀態',
        info: '詳細資訊',
        latitude: '緯度',
        longitude: '經度',
        address: '地址',
        phone: '電話',

        id: '編號',
        update_time: '更新時間',
        create_time: '建立時間',
      },
    },
    'all_bonuses/records': {
      name: '兌換紀錄',
      fields: {
        user_id: '使用者編號',

        id: '編號',
        info: '產品名稱',
        create_time: '建立時間',
        change: '增減',
        provider: '使用途徑',
        product_id: '兌換產品編號',
      },
    },
  },

  ra: {
    action: {
      unselect: '取消選擇',
      delete: '刪除',
      list: '列表',
      save: '保存',
      create: '新建',
      edit: '編輯',
      sort: '排序',
      cancel: '取消',
      undo: '恢復',
      refresh: '刷新',
      add: '增加',
      remove: '刪除',
      add_filter: '增加檢索',
      remove_filter: '移除檢索',
      back: '退回',
      bulk_actions: '選中%{smart_count}項',
      export: '輸出',
      search: '搜尋',
      show: '顯示',
    },
    boolean: {
      true: '是',
      false: '否',
    },
    page: {
      list: '%{name} 列表',
      edit: '%{name} #%{id}',
      show: '%{name} #%{id}',
      create: '新建 %{name}',
      dashboard: '主控台',
      not_found: '未找到',
      loading: '載入中',
      error: '出現錯誤',
      empty: '目前還沒有資料......',
      invite: '趕快點擊加號新增資料吧！',
    },
    input: {
      file: {
        upload_several: '將文件集合拖拽到這裡, 或點擊這裡選擇文件集合.',
        upload_single: '將文件拖拽到這裡, 或點擊這裡選擇文件.',
      },
      image: {
        upload_several: '將圖片文件集合拖拽到這裡, 或點擊這裡選擇圖片文件集合.',
        upload_single: '將圖片文件拖拽到這裡, 或點擊這裡選擇圖片文件.',
      },
      references: {
        all_missing: '未找到參考數據.',
        many_missing: '至少有一條參考數據不再可用.',
        single_missing: '關聯的參考數據不再可用.',
      },
    },
    message: {
      yes: '是',
      no: '否',
      are_you_sure: '您確定要這樣做嗎?',
      about: '關於',
      not_found: '您輸入了錯誤的URL或者錯誤的鏈接.',
      loading: '正在載入頁面, 請稍候',
      invalid_form: '表單輸入無效. 請檢查錯誤提示',
      delete_title: '刪除 %{name} #%{id}',
      delete_content: '您確定要刪除該項目?',
      bulk_delete_title: '刪除 %{name} |||| 刪除 %{smart_count}項 %{name} ',
      bulk_delete_content:
        '您確定要刪除 %{name}? |||| 您確定要刪除 %{smart_count} 項?',
    },
    navigation: {
      no_results: '',
      no_more_results: '頁碼 %{page} 超出邊界. 試試上一頁.',
      page_out_of_boundaries: '頁碼 %{page} 超出邊界',
      page_out_from_end: '已到最末頁',
      page_out_from_begin: '已到最前頁',
      page_range_info: '%{offsetBegin}-%{offsetEnd} / %{total}',
      page_rows_per_page: '每頁行數:',
      next: '向後',
      prev: '向前',
      skip_nav: 'skip_nav',
    },
    auth: {
      user_menu: '設定',
      username: '使用者名稱',
      password: '密碼',
      sign_in: '登錄',
      sign_in_error: '驗證失敗, 請重試',
      logout: '退出',
    },
    notification: {
      updated: '項目已更新 |||| %{smart_count} 項項目已更新',
      created: '項目已新建',
      deleted: '項目已刪除 |||| %{smart_count} 項項目已刪除',
      bad_item: '不正確的項目',
      item_doesnt_exist: '項目不存在',
      http_error: '與服務通信出錯',
      canceled: '取消動作',
      data_provider_error: 'dataProvider錯誤. 請檢查console的詳細信息.',
    },
    validation: {
      required: '必填',
      minLength: '必須不少於 %{min} 個字',
      maxLength: '必須不多於 %{max} 個字',
      minValue: '必須不小於 %{min}',
      maxValue: '必須不大於 %{max}',
      number: '必須為數字',
      email: '必須是有效的電子信箱',
      oneOf: '必須為: %{options}其中一項',
      regex: '必須符合指定的格式 (regexp): %{pattern}',
    },
  },
})

const i18nProvider = polyglotI18nProvider(() => myChineseTWMessages, 'zh-tw', {
  allowMissing: true,
})

export default i18nProvider
