import { Chip } from '@mui/material'
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  TextField,
  TextInputProps,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { xlsxExporter } from 'src/utils/utils'

const QuickFilter = ({ label }: TextInputProps) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />
}

export const BonusList = (props) => (
  <List
    exporter={xlsxExporter}
    sort={{ field: 'create_time', order: 'DESC' }}
    filters={[
      <QuickFilter
        key="product_id"
        label="兌換紀錄"
        source="product_id@_is_null"
        defaultValue={false}
      />,
    ]}
    {...props}
  >
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        label="使用者"
        render={(record) => (
          <Link to={`/user/${record.user_id}/show`}>
            {record.user.username}
          </Link>
        )}
      />
      <TextField source="info" />
      <NumberField source="change" />
      <DateField source="create_time" showTime />
      <DateField source="expired_time" showTime />
      <TextField source="provider" />
      <FunctionField
        label="product"
        render={(record) => (
          <Link to={`/product/${record?.product_id}/show`}>
            {record?.product?.product_name}
          </Link>
        )}
      />
      <TextField source="id" />
    </Datagrid>
  </List>
)
