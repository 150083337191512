import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { xlsxExporter } from 'src/utils/utils'

export const ProductOrderList = (props) => (
  <List
    exporter={xlsxExporter}
    sort={{ field: 'create_time', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <FunctionField
        label="使用者"
        render={(order) => (
          <Link to={`/user/${order.user_id}/show`}>{order.user.username}</Link>
        )}
      />

      <ArrayField sortable={false} source="product_order_items">
        <Datagrid bulkActionButtons={false}>
          <TextField source="product_id" />
          <TextField source="price" />
          <TextField source="quantity" />
          <TextField source="note" />
        </Datagrid>
      </ArrayField>

      <DateField source="create_time" showTime />
      <TextField source="payment_status" />
      <TextField source="total_amount" />
      <TextField source="phone" />
      <TextField source="email" />
      <TextField source="receipt_address" />
      <TextField source="note" />
      <BooleanField source="active" />
      <TextField source="state" />
    </Datagrid>
  </List>
)

export const ProductOrderShow = ({ ...props }) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <FunctionField
          label="使用者"
          render={(order) => (
            <Link to={`/user/${order.user_id}/show`}>
              {order.user.username}
            </Link>
          )}
        />

        <ArrayField source="product_order_items">
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="商品名"
              render={(item) => (
                <Link to={`/product/${item.product_id}/show`}>
                  {item.product.product_name}
                </Link>
              )}
            />
            <TextField source="price" />
            <TextField source="quantity" />
            <TextField source="note" />
          </Datagrid>
        </ArrayField>

        <DateField source="create_time" showTime />
        <TextField source="payment_status" />
        <TextField source="total_amount" />
        <TextField source="phone" />
        <TextField source="email" />
        <TextField source="receipt_address" />
        <TextField source="note" />
        <BooleanField source="active" />
        <TextField source="state" />
      </SimpleShowLayout>
    </Show>
  )
}
