import { Avatar, Chip, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { saveAs } from 'file-saver'
import html2pdf from 'html2pdf.js'
import JSZip from 'jszip'
import { useRef, useState } from 'react'
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceInput,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  TextInputProps,
  TopToolbar,
  useGetList,
  useListContext,
  useListFilterContext,
  useListSortContext,
  useNotify,
  usePermissions,
} from 'react-admin'
import { ImportButton } from 'react-admin-import-csv'
import { useSearchParams } from 'react-router-dom'
import { Role } from 'src/authProvider'
import CupCard from 'src/Components/CupCard'
import SmallImageField from 'src/Components/SmallImageField'
import { xlsxExporter } from 'src/utils/utils'

import { 立牌ToPrint } from './qrcode'

const useStyles = makeStyles(() => ({
  avatar: {
    width: 64,
    height: 64,
  },
  inlineBlock: {
    display: 'inline-flex',
    width: '40%',
    maxWidth: 150,
    marginRight: '1rem',
  },
}))

const ListActions = () => {
  const { sort } = useListSortContext()
  const { filterValues } = useListFilterContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const [response, setResponse] = useState<any>()
  return (
    <TopToolbar>
      <Button
        color={
          sort.field === 'cup_nums_aggregate.sum.clean_number'
            ? 'primary'
            : 'inherit'
        }
        onClick={() => {
          const updatedSearchParams = new URLSearchParams(
            searchParams.toString()
          )
          updatedSearchParams.set('sort', 'cup_nums_aggregate.sum.clean_number')
          updatedSearchParams.set('order', 'ASC')
          updatedSearchParams.set(
            'filter',
            JSON.stringify({
              ...filterValues,
              store_type: 'store',
            })
          )
          setSearchParams(updatedSearchParams.toString())
        }}
        size="small"
        variant="contained"
      >
        配送
      </Button>
      <Button
        color={
          sort.field === 'cup_nums_aggregate.sum.dirty_number'
            ? 'primary'
            : 'inherit'
        }
        onClick={() => {
          const updatedSearchParams = new URLSearchParams(
            searchParams.toString()
          )
          updatedSearchParams.set('sort', 'cup_nums_aggregate.sum.dirty_number')
          updatedSearchParams.set('order', 'DESC')
          updatedSearchParams.set(
            'filter',
            JSON.stringify({
              ...filterValues,
              store_type: undefined,
            })
          )
          setSearchParams(updatedSearchParams.toString())
        }}
        size="small"
        variant="contained"
      >
        回收
      </Button>
      <CreateButton />
      <ImportButton
        resource={''}
        postCommitCallback={(response) => {
          setResponse(response)
        }}
      />
      <pre>{JSON.stringify(response, null, 2)}</pre>
    </TopToolbar>
  )
}

const QuickFilter = ({ label }: TextInputProps) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />
}

export const StoreList = (props) => {
  const classes = useStyles()
  const { permissions } = usePermissions()

  const isSmall = useMediaQuery((theme) =>
    (theme as any).breakpoints.down('lg')
  )

  const filters = [
    <TextInput key="id" label="id" source="id@_eq" alwaysOn size="small" />,
  ]
    .concat(
      permissions === 'admin'
        ? [
            <ReferenceInput
              alwaysOn
              key="staff"
              source="store_managements#staff_id@_eq"
              reference="staff"
            >
              <AutocompleteInput
                size="small"
                label="區域管理員"
                optionText="username"
              />
            </ReferenceInput>,
          ]
        : []
    )
    .concat([
      <TextInput
        key="store"
        label="商店"
        source="store_name@_ilike"
        alwaysOn
      />,
      <BooleanInput
        sx={{ marginBottom: 1 }}
        key="active"
        label="未封存店家"
        source="active"
        defaultChecked={true}
        alwaysOn
      />,
      <QuickFilter
        key="store_type"
        source="store_type"
        label="僅顯示店家"
        defaultValue={'store'}
      />,
    ])

  const commonListProps: Partial<ListProps> = {
    exporter: xlsxExporter,
    title: <StoreTitle />,
    actions: <ListActions />,
    perPage: 30,
    bulkActionButtons: false,
    sort: {
      field: 'cup_nums_aggregate.sum.dirty_number',
      order: 'desc_nulls_last' as any,
    },
    filters,
    filterDefaultValues: { active: true },
  }

  if (isSmall) {
    return (
      <List {...commonListProps} {...props}>
        <SimpleList
          primaryText={(record) => {
            return (
              <div style={{ display: 'flex', overflow: 'hidden' }}>
                <div style={{ flex: '0 0 64px' }}>
                  <Avatar
                    variant="rounded"
                    src={record.image_url}
                    className={classes.avatar}
                  />
                </div>

                <div
                  style={{
                    flex: '1 1 45%',
                    marginLeft: 16,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {record.store_name}
                </div>
                <div style={{ flex: '1 0 35%' }}>
                  {record.cup_nums.map((cup_num) => (
                    <div key={cup_num.id} style={{ display: 'flex' }}>
                      <Typography
                        sx={{ flex: 1 }}
                        variant="body2"
                        display={'inline'}
                        color={'textSecondary'}
                      >
                        {`${cup_num?.cup_type?.name ?? 'uCup'}`}
                      </Typography>
                      <Typography
                        sx={{ flex: 1 }}
                        variant="body2"
                        display={'inline'}
                        color={
                          cup_num?.clean_number < 10 ? 'error' : 'textSecondary'
                        }
                      >
                        {`${cup_num?.clean_number ?? 0}`}
                      </Typography>
                      <Typography
                        sx={{ flex: 1 }}
                        variant="body2"
                        display={'inline'}
                        color={
                          cup_num?.dirty_number > 0 ? 'error' : 'textSecondary'
                        }
                      >
                        {cup_num?.dirty_number ?? 0}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            )
          }}
          linkType={'edit'}
        />
      </List>
    )
  }
  return (
    <List {...commonListProps} {...props}>
      <Datagrid
        bulkActionButtons={
          <>
            <BulkDownloadQrcodeButton />
          </>
        }
        rowClick="edit"
      >
        {permissions === Role.ADMIN && (
          <ArrayField sortable={false} source="store_managements" emptyText=" ">
            <Datagrid bulkActionButtons={false} header={<></>}>
              <TextField source="staff.username" />
            </Datagrid>
          </ArrayField>
        )}
        <SmallImageField source="image_url" />
        <TextField source="store_name" />

        <ArrayField sortable={false} source="cup_nums" emptyText=" ">
          <Datagrid
            sx={{
              '& .RaDatagrid-rowCell': {
                padding: 0.5,
                minWidth: 50,
                maxWidth: 50,
              },
              '& .RaDatagrid-thead': {
                height: 0,
                maxHeight: 0,
                display: 'none',
              },
            }}
            bulkActionButtons={false}
            // header={<></>}
          >
            <SmallImageField source="cup_type.image_url" />
            <TextField label="乾淨杯子數" source="clean_number" />
            <TextField label="待回收杯子數" source="dirty_number" />
          </Datagrid>
        </ArrayField>
        <TextField source="phone" />
        <TextField source="store_type" />
        <BooleanField source="rent_available" />
        <BooleanField source="return_available" />
        <DateField source="create_time" />
        <BooleanField source="active" />
        <TextField label="顯示順序" source="display_order" />
        <TextField source="id" />
      </Datagrid>
      <CupTypeSum />
    </List>
  )
}

const CupTypeSum = () => {
  const { filterValues: storeFilterValues } = useListContext()
  // for key in storeFilterValues, add "store#" before the key
  const filter = Object.keys(storeFilterValues).reduce((acc, key) => {
    acc[`store#${key}`] = storeFilterValues[key]
    return acc
  }, {})
  const { data, total, isLoading, error } = useGetList('cup_num', {
    filter,
    // no pagination
    pagination: {
      page: 1,
      perPage: Infinity,
    },
  })

  if (isLoading) {
    return <></>
  }
  if (error) {
    return <p>ERROR</p>
  }
  if (data?.length === 0) {
    return <></>
  }

  /*
  data: [
   {
    cup_type_id
    clean_number
    dirty_number
  },
  ...
  ]
  const table = sum(clean_number), sum(dirty_number) group by cup_type_id
  */
  const cupTypeSum: Record<
    string,
    { clean_number: number; dirty_number: number }
  > =
    data?.reduce(
      (acc, { cup_type_id, clean_number, dirty_number, cup_type }) => {
        if (!acc[cup_type_id]) {
          acc[cup_type_id] = { clean_number: 0, dirty_number: 0, cup_type }
        }
        acc[cup_type_id].clean_number += clean_number ?? 0
        acc[cup_type_id].dirty_number += dirty_number ?? 0
        return acc
      },
      {} as Record<string, { clean_number: number; dirty_number: number }>
    ) ?? {}

  const cupTypeSumArray: {
    id: string
    cup_type?: {
      id?: string
      name?: string
      image_url?: string
    }
    clean_number?: number
    dirty_number?: number
  }[] = Object.entries<any>(cupTypeSum).map(
    ([cup_type_id, { clean_number, dirty_number, cup_type }]) => ({
      id: cup_type_id,
      cup_type,
      clean_number,
      dirty_number,
    })
  )

  return (
    <div style={{ margin: 16 }}>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2,
        }}
      >
        杯子總數
      </Typography>

      <CupCard
        showAll
        showDirtyNumber
        cupNums={cupTypeSumArray}
        loading={isLoading}
      />
    </div>
  )
}

const StoreTitle = ({ record = '' }: { record?: any }) => {
  return <span>店家 {record ? `"${record.id}"` : ''}</span>
}

export const StoreCreate = (props) => {
  return (
    <Create {...props} resource="stores/signup" redirect="/store">
      <SimpleForm>
        <TextInput source="phone" />
        <TextInput source="password" />
        <TextInput source="confirm" />
        <TextInput source="store_name" />
      </SimpleForm>
    </Create>
  )
}

const BulkDownloadQrcodeButton = () => {
  const { selectedIds, data } = useListContext()
  const selectedItems = data.filter((item) => selectedIds.includes(item.id))
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const handleClick = async () => {
    if (selectedIds.length === 0) {
      notify('請選擇店家')
      return
    }
    try {
      setLoading(true)
      const list = ref.current
      if (!list) return
      // convert to image and zip and download
      const zip = new JSZip()

      // for each element under list
      for (let index = 0; index < list.childNodes.length; index++) {
        const element = list.childNodes[index]
        const elementId = (element as Element)?.id

        const pdfBlob = await html2pdf()
          .set({
            jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
          })
          .from(element)
          .toPdf()
          .get('pdf')
          .then(function (pdf) {
            const totalPages = pdf.internal.getNumberOfPages()
            if (totalPages > 1) {
              for (let i = 2; i <= totalPages; i++) {
                pdf.deletePage(2)
              }
            }
            return pdf
          })
          .outputPdf('blob')
        zip.file(`${elementId}.pdf`, pdfBlob)
        console.log({ pdfBlob, t: typeof pdfBlob })
      }

      const content = await zip.generateAsync({ type: 'blob' })
      saveAs(content, 'qrcode.zip')
    } catch (e) {
      notify('下載失敗: ' + (e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button disabled={loading} onClick={handleClick}>
        下載Qrcode
      </Button>
      <div ref={ref} style={{ display: 'none' }}>
        {selectedItems.map(({ id, store_name }) => (
          <立牌ToPrint key={id} storeId={id} storeName={store_name} />
        ))}
      </div>
    </>
  )
}
