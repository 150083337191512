import './ZXingScanner.css'

import CloseIcon from '@mui/icons-material/Close'
import FlashOffIcon from '@mui/icons-material/FlashOff'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library'
import React, { useEffect, useState } from 'react'

const codeReader = new BrowserMultiFormatReader()

const longText = `請用chrome或safari等開啟（點擊右上方的三個點點，選擇以其他應用程式開啟或類似按鈕）`

function ZXingScanner({ onDetected, onClose, facingMode }) {
  const [scanned, setScanned] = useState(false)

  const handleDetected = (result) => {
    if (scanned === false) {
      setScanned(true)
      codeReader.reset()
      onDetected(result)
    }
  }

  useEffect(() => {
    const hdConstraints = {
      video: {
        width: { min: 640, ideal: 1920, max: 1920 },
        height: { min: 480, ideal: 1080, max: 1080 },
        frameRate: { min: 20, ideal: 24, max: 30 },
        facingMode: facingMode ?? 'environment',
      },
    }

    codeReader.decodeFromConstraints(
      hdConstraints,
      'video',
      (result, error) => {
        if (result) {
          handleDetected(result.text)
        }
        if (error && !(error instanceof NotFoundException)) {
          alert(error)
        }
      }
    )

    return () => {
      codeReader.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [torch, setTorch] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggleTorch = () => {
    const video = document.querySelector('#video')
    // get the active track of the stream
    const track = video.srcObject.getVideoTracks()[0]
    if (track.getCapabilities().torch) {
      track.applyConstraints({
        advanced: [{ torch: !torch }],
      })
      setTorch(!torch)
    } else {
      alert('你的裝置不能用閃光燈')
    }
  }

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton onClick={onClose} aria-label="close" size="large">
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={toggleTorch}
            aria-label="flashlight"
            size="large"
          >
            {torch ? <FlashOnIcon /> : <FlashOffIcon />}
          </IconButton>

          <IconButton aria-label="help" size="large">
            <Tooltip title={longText} open={tooltipOpen}>
              <HelpOutlineIcon onClick={() => setTooltipOpen(!tooltipOpen)} />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div>
        <video id="video"></video>
      </div>
    </>
  )
}

export default ZXingScanner
