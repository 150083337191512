import CircularProgress from '@mui/material/CircularProgress'
import Fade from '@mui/material/Fade'
import React from 'react'

function Loading() {
  const [loading] = React.useState(true)
  const timerRef = React.useRef()

  React.useEffect(
    () => () => {
      clearTimeout(timerRef.current)
    },
    []
  )

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: 40,
        }}
      >
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress color="secondary" />
        </Fade>
      </div>
    </div>
  )
}

Loading.defaultProps = {
  open: true,
}

export default Loading
