// components
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useRef, useState } from 'react'
import { useCreate, useGetIdentity, useGetOne } from 'react-admin'

import CupCard from '../Components/CupCard'
import { determineProvider, vibrate } from '../utils/utils'
import errorImg from './error.jpg'
import successImg from './success.jpg'

const PREFIX = 'Business'

const classes = {
  root: `${PREFIX}-root`,
  checked: `${PREFIX}-checked`,
  root2: `${PREFIX}-root2`,
  root3: `${PREFIX}-root3`,
  root4: `${PREFIX}-root4`,
  business: `${PREFIX}-business`,
  cupCard: `${PREFIX}-cupCard`,
  form: `${PREFIX}-form`,
  idInputRow: `${PREFIX}-idInputRow`,
  scannerColumn: `${PREFIX}-scannerColumn`,
  actionRow: `${PREFIX}-actionRow`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.business}`]: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '70vh',
    marginBottom: '10vh',
  },

  [`& .${classes.cupCard}`]: {
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    flex: '1 0 auto',
  },

  [`& .${classes.idInputRow}`]: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },

  [`& .${classes.scannerColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.actionRow}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

// others

let provider: string | undefined = undefined

function BoxWithDevice() {
  const successAudioRef = useRef(new Audio('rent-success.mp3'))
  const failedAudioRef = useRef(new Audio('rent-failed.mp3'))

  const { data } = useGetIdentity()
  const storeQuery = useGetOne('stores', {
    id: data?.id,
  })
  const cupNums = storeQuery.data?.cup_nums

  const [userId, setUserId] = useState('')
  const [selfRentOrReturn, setSelfRentOrReturn] = useState('rent')
  const cupType = selfRentOrReturn === 'Self' ? 'Self' : 'uCup'
  const [inputError, setInputError] = useState(false)

  const [scanning, setScanning] = useState(false)

  const [isReading, setIsReading] = useState(true)
  useEffect(() => {
    if (!isReading) {
      return
    }
  }, [isReading])

  const onDetected = (newDetectResult) => {
    setScanning(false)

    vibrate()

    provider = determineProvider(newDetectResult)

    if (provider === 'invalid') {
      notify(`${newDetectResult} 條碼格式不符或掃描失敗，請重新掃描！`)
      return
    } else if (provider === 'NTU Mail') {
      newDetectResult = newDetectResult.slice(0, -1)
    }

    setUserId(newDetectResult)
    handleComfirm(newDetectResult)
  }

  const [notification, setNotification] = useState('')
  const [notificationType, setNotificationType] = useState('')
  const notify = (msg: string, options = { type: 'info' }) => {
    setIsReading(false)
    setTimeout(() => {
      setIsReading(true)
    }, 5_000)

    setNotification(msg)
    setNotificationType(options.type)
  }
  const [create, { isLoading }] = useCreate()
  const doRent = (userId) =>
    create(
      'record/do_rent',
      {
        data: {
          user_id: provider === 'NTU Mail' ? userId.toUpperCase() : userId,
          provider: provider,
          cup_type: cupType,
          change: 1,
        },
      },
      {
        onSuccess: () => {
          successAudioRef.current.play()
          setUserId('')
          notify(cupType === 'Self' ? '自備成功！' : '租借成功！', {
            type: 'success',
          })
          storeQuery.refetch()
        },
        onError: (error) => handleMutationError(error, '租借', userId),
      }
    )
  const doReturn = (userId) =>
    create(
      'record/do_return',
      {
        data: {
          user_id: provider === 'NTU Mail' ? userId.toUpperCase() : userId,
          provider: provider,
          change: 1,
          cup_type: cupType,
        },
      },
      {
        onSuccess: () => {
          successAudioRef.current.play()
          setUserId('')
          notify('歸還成功！', {
            type: 'success',
          })
          storeQuery.refetch()
        },
        onError: (error) => handleMutationError(error, '歸還', userId),
      }
    )
  const handleMutationError = (error, type, userId) => {
    failedAudioRef.current.play()
    setUserId('')
    notify(`${type}錯誤： ${error.body.result} ${userId}`, {
      type: 'warning',
    })
  }

  const handleComfirm = async (userId) => {
    // Display scan result and prompt
    provider = determineProvider(userId)
    if (provider === 'invalid') {
      setInputError(true)
      return
    }

    setInputError(false)
    if (selfRentOrReturn === 'Self') {
      doRent(userId)
    } else if (selfRentOrReturn === 'rent') {
      doRent(userId)
    } else if (selfRentOrReturn === 'return') {
      doReturn(userId)
    }
  }

  useEffect(() => {
    const num = Number(userId)
    const serial = num.toString(16)
    if (determineProvider(serial) === 'NFC') {
      const reversedHexArray = serial.match(/[a-fA-F0-9]{2}/g)
      if (reversedHexArray === null) {
        throw Error('unknown error')
      }
      const reversedHex = reversedHexArray.reverse().join('')

      handleComfirm(reversedHex)
    }
  }, [userId])

  return (
    <Root className={classes.business}>
      <div>
        <div className={classes.cupCard}>
          <CupCard loading={storeQuery.isLoading} cupNums={cupNums} />
        </div>

        {isReading ? (
          <div>
            <Typography variant="h5" align="center">
              學生證感應讀卡機
            </Typography>
            <TextField
              sx={{ marginTop: 4 }}
              autoFocus
              type="number"
              InputLabelProps={{ shrink: true }}
              error={inputError}
              helperText={'請將學生證感應讀卡機'}
              required
              id="user-id"
              label="使用者編號"
              color="primary"
              value={userId}
              onChange={(event) => {
                setUserId(event.target.value)
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              style={{ margin: 16 }}
              width={128}
              height={128}
              alt="notification"
              src={notificationType === 'success' ? successImg : errorImg}
            ></img>
            <Typography
              variant="h5"
              align="center"
              color={notificationType === 'success' ? 'green' : 'red'}
            >
              {notification}
            </Typography>
          </div>
        )}
      </div>
    </Root>
  )
}

export default BoxWithDevice
