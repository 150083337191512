import {
  CREATE,
  DataProvider,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  LegacyDataProvider,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin'

import useHasuraDataProvider from './hasuraDataProvider'
import highWallDataProvider from './highWallDataProvider'

interface DataProviderMapping {
  delayed?: boolean
  default?: boolean
  dataProvider: DataProvider | LegacyDataProvider | undefined
  resources: string[]
}

interface UseDataProviderResult {
  dataProvider: LegacyDataProvider | undefined
  loading: boolean
  error: unknown
}
type UseDataProvider = () => UseDataProviderResult

const useDataProvider: UseDataProvider = () => {
  /* Configuration */
  const {
    dataProvider: hasuraDataProvider,
    loading,
    error,
  } = useHasuraDataProvider()

  const dataProviderMappings: DataProviderMapping[] = [
    {
      default: true,
      dataProvider: highWallDataProvider,
      resources: ['stores/cup_num'],
    },
    {
      delayed: true,
      dataProvider: hasuraDataProvider,
      /*
      ***Important Note*** 
        If you add resources,
        you must add <Resource /> components to the <Admin>.
      */
      resources: [
        'rent',
        'return',
        'user',
        'social_connection',
        'store',
        'product',
        'bonus',
        'coupon',
        'cup_num_record',
        'cup',
        'cup_type',
        'cup_num',
        'cup_washed_record',
        'subscription',
        'order',
        'staff',
        'store_management',
        'product_order',
        'store_notify',
      ],
    },
  ]
  /* Configuration */

  const defaultDataProvider =
    dataProviderMappings.find((dpm) => dpm.default === true)?.dataProvider ??
    undefined

  const findMatchedDataProvider = (resource: string) => {
    const matchedDPM = dataProviderMappings.find((dpm) =>
      dpm.resources.includes(resource)
    )
    if (matchedDPM === undefined || matchedDPM.dataProvider === undefined) {
      if (defaultDataProvider === undefined) {
        throw new Error(`No data provider found for ${resource}`)
      }

      return defaultDataProvider
    }
    return matchedDPM.dataProvider
  }

  const dataProvider: LegacyDataProvider = (...args) => {
    const type = args[0]
    let resource = args[1]
    const params = args[2]
    let dp = findMatchedDataProvider(resource)
    if (args[0] === 'createMany' && args[1] === 'store') {
      dp = highWallDataProvider
      resource = 'stores/signup_many'
      params.data = {
        inputs: params.data,
      }
    }
    console.log(args)
    if (typeof dp === 'function') {
      // LegacyDataProvider
      return dp(...args)
    } else {
      const mappingType = {
        [GET_LIST]: 'getList',
        [GET_ONE]: 'getOne',
        [GET_MANY]: 'getMany',
        [GET_MANY_REFERENCE]: 'getManyReference',
        [CREATE]: 'create',
        [UPDATE]: 'update',
        [UPDATE_MANY]: 'updateMany',
        [DELETE]: 'delete',
        createMany: 'createMany',
      }
      const action = mappingType[type]
      if (!action) {
        throw new Error(`Unsupported dataProvider action type ${type}`)
      }
      const func = dp[action]
      if (!func) {
        throw new Error(`Unsupported dataProvider action type ${type}`)
      }
      return func(resource, params)
    }
  }

  return { dataProvider, loading, error }
}

export default useDataProvider
